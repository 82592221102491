import * as Sentry from "@sentry/react";
import {
  DEPLOYMENT_ENV,
  IS_DEPLOYMENT_ENV_PROD,
  IS_DEPLOYMENT_ENV_TEST,
  IS_LOCAL,
  IS_SENTRY_ENABLED,
} from "../envVariables";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const sentryMetadata = { initialized: false };

export async function setupSentry() {
  if (!IS_SENTRY_ENABLED) {
    return;
  }

  if (sentryMetadata.initialized) {
    console.log("Skipping sentry initialize, already initialized.");
    return;
  }
  sentryMetadata.initialized = true;
  console.log("Initializing sentry.");

  const environment = IS_LOCAL ? "local" : DEPLOYMENT_ENV;
  try {
    let deploymentEnv = environment;
    let sentryServiceUrl = "https://sentry.services.controlplane.site/dsn/key";
    if (IS_LOCAL || IS_DEPLOYMENT_ENV_TEST) {
      // sentryServiceUrl = "https://staging.sentry.services.controlplane.site/dsn/key";
      deploymentEnv = "test";
    }

    const keyRes = await axios.post(sentryServiceUrl, { environment: deploymentEnv });
    const key = keyRes.data.public;

    // @ts-ignore
    const about = require("./../about.json");
    Sentry.init({
      dsn: key,
      sampleRate: 1.0,
      attachStacktrace: true,
      debug: !IS_DEPLOYMENT_ENV_PROD,
      release: about.version,
      maxBreadcrumbs: 30,
      normalizeDepth: 5,
      environment,
      autoSessionTracking: false,
      beforeBreadcrumb(crumb) {
        if (crumb.category === "console") return null;
        if (crumb.category === "xhr" && crumb.data?.status_code < 400) return null;
        if (crumb.category === "xhr" && crumb.data?.status_code === 403) return null;
        if (crumb.category === "xhr" && crumb.data?.status_code === 404) return null;
        if (crumb.category === "ui.click") return null;
        if (crumb.category === "ui.input") return null;
        return crumb;
      },
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 0.01,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
        }),
      ],
    });

    const feedback = Sentry.feedbackIntegration({
      autoInject: false,
      showBranding: false,
      triggerLabel: "Feedback",
      triggerAriaLabel: "Suggest a Feature or Report a Bug",
      formTitle: "Send Feedback/Bug Report",
      submitButtonLabel: "Submit",
      messageLabel: "Message",
      messagePlaceholder: "Please describe the issue and what you expected to happen, or suggest a feature.",
      successMessageText:
        "We appreciate you taking the time to share your thoughts about your recent experience with Control Plane. Your opinion is very important to us, and we truly value your feedback.",
      showName: false,
      showEmail: false,
      onSubmitSuccess: (feedback) => {
        Sentry.captureMessage("User Feedback Submitted", { level: "info" });
        Sentry.setTag("sessionId", null);
      },
      onSubmitError: (error) => {
        Sentry.captureMessage("User Feedback Failed to Submit", { level: "error" });
        Sentry.setTag("sessionId", null);
      },
      onFormOpen: () => {
        const sessionId = uuidv4();
        Sentry.setTag("sessionId", sessionId);
      },
    });

    const feedbackElement = document.getElementById("feedback-button")!;
    if (!feedbackElement) {
      console.log("No feedback element");
    } else {
      const form = await feedback.createForm();
      form.appendToDom();
      feedbackElement.addEventListener("click", async () => {
        form.open();
      });
    }

    Sentry.addIntegration(feedback);
  } catch (e) {
    console.error("Failed to initialize Sentry", e.message);
  }
}
