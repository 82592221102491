import { types, flow, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel, defaultValues } from "../base";
import { QueryModel } from "../query";
import { request } from "../../services/cpln";
import { NameValueModel } from "./../base";
import { TracingModel } from "./tracing";
import { Gvc, GvcSpec, StaticPlacement } from "../../schema/types/gvc";

const StaticPlacementModel = types
  .model("GVC Static Placement", {
    locationLinks: types.array(types.string),
    locationQuery: types.maybe(types.late(() => QueryModel)),
  })
  .views((self) => ({
    get locations(): string[] {
      return self.locationLinks.map((loc) => loc.split("/").pop()) as string[];
    },
  }))
  .actions((self) => {
    function setLocationLinks(links: string[]) {
      self.locationLinks.clear();
      links.forEach((link) => self.locationLinks.push(link));
    }
    return { setLocationLinks };
  });
export interface StaticPlacementMobx extends Instance<typeof StaticPlacementModel> {}

export function getDefaultStaticPlacement(): StaticPlacement {
  return {
    locationLinks: [],
  };
}

const GVCSpecModel = types
  .model("GVC Spec", {
    staticPlacement: types.optional(StaticPlacementModel, () => StaticPlacementModel.create()),
    pullSecretLinks: types.array(types.string),
    domain: types.maybe(types.string),
    env: types.array(NameValueModel),
    tracing: types.maybe(TracingModel),
    loadBalancer: types.maybe(
      types.model({
        dedicated: types.optional(types.boolean, false),
        trustedProxies: types.optional(types.number, 0), // min 0 , max 2
        redirect: types.maybe(
          types.model({
            class: types.maybe(
              types.model({
                status5xx: types.maybe(types.string),
                status401: types.maybe(types.string),
              }),
            ),
          }),
        ),
      }),
    ),
    sidecar: types.maybe(
      types.model({
        envoy: types.maybe(types.frozen()),
      }),
    ),
  })
  .views((self) => ({
    get currentDomain() {
      return self.domain || defaultValues.domain.value;
    },
  }));
export interface GVCSpecMobx extends Instance<typeof GVCSpecModel> {}

export function getDefaultGvcSpec(): GvcSpec {
  return {
    staticPlacement: getDefaultStaticPlacement(),
    env: [],
    pullSecretLinks: [],
  };
}

export const GVCModel = types
  .compose(
    "Gvc",
    BaseModel,
    types.model({
      alias: types.optional(types.string, ""),
      spec: GVCSpecModel,
      status: types.frozen(),
    }),
  )
  .actions((self) => {
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, data);
    });
    return { fetch };
  })
  .actions((self) => {
    const patch: (body: Object) => Promise<void> = flow(function* (body: Object) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  });
export interface GVCMobx extends Instance<typeof GVCModel> {}

export function getDefaultGvc(): Gvc {
  return {
    alias: "",
    created: new Date().toString(),
    lastModified: new Date().toString(),
    description: "",
    id: "",
    kind: "gvc",
    links: [],
    name: "",
    spec: getDefaultGvcSpec(),
    tags: {},
    version: 1,
  };
}
