import React from "react";
import { X } from "react-feather";
import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import "./styles.scss";
import { NGButton } from "../../../newcomponents/button/Button";
import { UIData } from "../../../mobxStores/uiData/uiData";
import { IssuesDrawerContent } from "./issue";

export enum EDrawerType {
  NONE = "NONE",
  ISSUES = "ISSUES",
}

interface IDrawerComponentProps extends React.PropsWithChildren {
  title: string;
  subtitle?: string;
  onClose: () => void;
}
const DrawerComponent: React.FC<IDrawerComponentProps> = ({ title, subtitle, onClose, children }) => {
  const [isClosing, setIsClosing] = React.useState(false);

  React.useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        onClose();
        setIsClosing(false);
      }, 300); // Match the duration of the slide-out animation

      return () => clearTimeout(timer);
    }
    return;
  }, [isClosing, onClose]);

  const handleClose = () => {
    setIsClosing(true);
  };

  return createPortal(
    <div className={`drawer-container ${isClosing ? "fade-out" : ""}`}>
      <div className={`drawer-backdrop ${isClosing ? "fade-out" : ""}`} />
      <div className={`drawer ${isClosing ? "slide-out" : ""}`}>
        <div className="drawer-header">
          <div className="drawer-header-content">
            <h1 className="drawer-header-title">{title}</h1>
            <h3 className="drawer-header-subtitle">{subtitle}</h3>
          </div>
          <NGButton
            size="toRemoveLarge"
            onClick={handleClose}
            variant="secondary"
            renderIcon={(_, { size, ...rest }) => <X {...rest} size={20} style={{ width: 20, height: 20 }} />}
            text
          />
        </div>
        <div className="drawer-content">{children}</div>
      </div>
    </div>,
    document.body,
  );
};

const DrawerRaw: React.FC = () => {
  const type = UIData.drawerType;
  let data: { title: string; subtitle?: string } | undefined = undefined;
  if (type === EDrawerType.ISSUES) {
    data = { title: "Issues", subtitle: "All of the issues that you've reported are here." };
  }

  if (type === EDrawerType.NONE || !data) {
    return;
  }

  return (
    <DrawerComponent {...data} onClose={() => UIData.setDrawerType(EDrawerType.NONE)}>
      {type === EDrawerType.ISSUES ? <IssuesDrawerContent /> : null}
    </DrawerComponent>
  );
};

export const Drawer = observer(DrawerRaw);
