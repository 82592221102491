import { types, flow, isAlive, applySnapshot } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { BaseModel, defaultValues, NameValueModel, NameValue } from "../base";
import { automatedRequest, request } from "../../services/cpln";
import { workloadHelpers } from "./workload.helpers";

const HealthCheckHttpGetModel = types.model("HealthHttpGet", {
  path: types.optional(types.string, "/"),
  port: types.maybe(types.number),
  httpHeaders: types.array(NameValueModel),
  scheme: types.optional(types.string, "HTTP"), // HTTP HTTPS
});
export interface HealthCheckHttpGetMobx extends Instance<typeof HealthCheckHttpGetModel> {}
export interface HealthCheckHttpGet {
  path: string;
  port?: number;
  httpHeaders: NameValue[];
  scheme: "HTTP" | "HTTPS" | string;
}

const HealthCheckSpecModel = types
  .model("HealthCheckSpec", {
    exec: types.maybe(
      types.model("HealthExec", {
        command: types.array(types.string),
      }),
    ),
    tcpSocket: types.maybe(
      types.model("HealthTcpSocket", {
        port: types.maybe(types.number),
      }),
    ),
    grpc: types.maybe(
      types.model("HealthGrpc", {
        port: types.maybe(types.number),
      }),
    ),
    httpGet: types.maybe(HealthCheckHttpGetModel),
    initialDelaySeconds: types.optional(types.number, 0),
    periodSeconds: types.optional(types.number, 10),
    timeoutSeconds: types.optional(types.number, 1),
    successThreshold: types.optional(types.number, 1),
    failureThreshold: types.optional(types.number, 3),
  })
  .views((self) => ({
    get hasMethod() {
      return workloadHelpers.healthCheck.getHasMethod(self as any);
    },
    get method() {
      return workloadHelpers.healthCheck.getMethod(self as any);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      // required for edit functionality
      if (self.hasMethod) return;
      self.tcpSocket = { port: defaultValues.port.default };
    },
  }));
export interface HealthCheckSpecMobx extends Instance<typeof HealthCheckSpecModel> {}

export const ContainerVolumeSpecModel = types.model({
  uri: types.string,
  recoveryPolicy: types.optional(types.string, "retain"), // "retain", "recycle"
  path: types.string,
  mode: types.optional(types.string, "ReadOnly"),
});
export interface ContainerVolumeSpecMobx extends Instance<typeof ContainerVolumeSpecModel> {}

export const ContainerLifecycleModel = types.model({
  postStart: types.maybe(
    types.model({
      exec: types.maybe(
        types.model({
          command: types.array(types.string),
        }),
      ),
    }),
  ),
  preStop: types.maybe(
    types.model({
      exec: types.maybe(
        types.model({
          command: types.array(types.string),
        }),
      ),
    }),
  ),
});
export interface ContainerLifecycleMobx extends Instance<typeof ContainerLifecycleModel> {}

const ContainerMetricsModel = types.model({
  path: types.optional(types.string, ""),
  port: types.optional(types.number, 0),
});
export interface ContainerMetricsMobx extends Instance<typeof ContainerMetricsModel> {}

const GPUModel = types.model("WorkloadContainerSpec", {
  nvidia: types.maybe(
    types.model({
      model: types.optional(types.string, ""), // t4, a10g
      quantity: types.optional(types.number, 1), // min 0, max 1 (t4) or 4(a10g)
    }),
  ),
});
export interface GPUMobx extends Instance<typeof GPUModel> {}

const ContainerSpecModel = types.model("WorkloadContainerSpec", {
  name: "main", // disallows istio-proxy queue-proxy istio-validation cpln-*
  image: "",
  port: types.maybe(types.number),
  ports: types.array(types.model({ protocol: types.string, number: types.number })),
  memory: types.optional(types.string, defaultValues.memory),
  cpu: types.optional(types.string, defaultValues.cpu),
  minMemory: types.optional(types.string, ""),
  minCpu: types.optional(types.string, ""),
  gpu: types.maybe(GPUModel),
  env: types.array(NameValueModel),
  inheritEnv: types.optional(types.boolean, false),
  args: types.array(types.string),
  readinessProbe: types.maybe(HealthCheckSpecModel),
  livenessProbe: types.maybe(HealthCheckSpecModel),
  volumes: types.array(ContainerVolumeSpecModel),
  lifecycle: types.maybe(ContainerLifecycleModel),
  command: types.optional(types.string, ""),
  workingDir: types.optional(types.string, ""),
  metrics: types.maybe(ContainerMetricsModel),
});
export interface ContainerSpecMobx extends Instance<typeof ContainerSpecModel> {}

const WorkloadStatusModel = types.model("WorkloadStatus", {
  endpoint: types.optional(types.string, ""),
  canonicalEndpoint: types.optional(types.string, ""),
  internalName: types.optional(types.string, ""),
  resolvedImages: types.maybe(
    types.model({
      errorMessages: types.array(types.string),
    }),
  ),
  loadBalancer: types.array(
    types.model({
      origin: types.string,
      url: types.string,
    }),
  ),
});
export interface WorkloadStatusMobx extends Instance<typeof WorkloadStatusModel> {}

const OptionsAutoscalingModel = types
  .model("WorkloadAutoscaleSpec", {
    metric: types.optional(types.string, "concurrency"), // "concurrency", "cpu", "rps", "memory", "latency", "disabled", virtually 'multi'
    target: types.optional(types.number, defaultValues.target.default),
    metricPercentile: types.optional(types.string, defaultValues.workload.options.metricPercentile),
    multi: types.maybe(
      types.array(types.model({ metric: types.enumeration(["cpu", "memory"]), target: types.number })),
    ),
    minScale: types.optional(types.number, defaultValues.minScale.default),
    maxScale: types.optional(types.number, defaultValues.maxScale.default),
    scaleToZeroDelay: types.optional(types.number, defaultValues.scaleToZeroDelay.default),
    maxConcurrency: types.optional(types.number, defaultValues.maxConcurrency.default),
  })
  .actions((self) => ({
    setMetric(value: "concurrency" | "cpu" | "memory" | "rps" | "latency" | "disabled" | "multi") {
      self.metric = value;
    },
  }));
export interface OptionsAutoscalingMobx extends Instance<typeof OptionsAutoscalingModel> {}

export const RolloutOptionsModel = types.model("WorkloadSpecRolloutOptions", {
  minReadySeconds: types.optional(types.number, defaultValues.workload.rolloutOptions.minReadySeconds.default),
  maxSurgeReplicas: types.optional(types.string, defaultValues.workload.rolloutOptions.maxSurgeReplicas.default),
  scalingPolicy: types.optional(types.string, "OrderedReady"),
});
export interface RolloutOptionsMobx extends Instance<typeof RolloutOptionsModel> {}

export const SecurityOptionsModel = types.model("WorkloadSpecSecurityOptions", {
  filesystemGroupId: types.maybe(types.number), // between 1 and 65534
});
export interface SecurityOptionsMobx extends Instance<typeof SecurityOptionsModel> {}

export const DefaultOptionsModel = types.model("WorkloadSpecDefaultOptions", {
  autoscaling: types.maybe(OptionsAutoscalingModel),
  timeoutSeconds: types.optional(types.number, 5), // min is 1
  capacityAI: types.optional(types.boolean, true),
  debug: types.optional(types.boolean, false),
  suspend: types.optional(types.boolean, false),
});
export interface DefaultOptionsMobx extends Instance<typeof DefaultOptionsModel> {}

const LocalOptionsModel = types.compose(
  "LocalOptionsModel",
  DefaultOptionsModel,
  types.model({
    location: types.string,
  }),
);
export interface LocalOptionsMobx extends Instance<typeof LocalOptionsModel> {}

const FirewallExternalSpecModel = types
  .model("FirewallExternalSpec", {
    inboundAllowCIDR: types.array(types.string),
    inboundBlockedCIDR: types.array(types.string),
    outboundAllowHostname: types.array(types.string),
    outboundAllowCIDR: types.array(types.string),
    outboundAllowPort: types.array(
      types.model({
        protocol: types.optional(types.enumeration(["http", "https", "tcp"]), "tcp"),
        number: types.number, // max 65000
      }),
    ),
  })
  .views((self) => ({
    get inboundInternetReachable() {
      return workloadHelpers.firewall.getInboundInternetReachable(self);
    },
    get outboundInternetReachable() {
      return workloadHelpers.firewall.getOutboundInternetReachable(self);
    },
    get inboundAllowCIDRFiltered() {
      return workloadHelpers.firewall.getInboundAllowCIDRFiltered(self);
    },
    get outboundAllowCIDRFiltered() {
      return workloadHelpers.firewall.getOutboundAllowCIDRFiltered(self);
    },
  }));
export interface FirewallExternalSpecMobx extends Instance<typeof FirewallExternalSpecModel> {}

const FirewallInternalSpecModel = types.model("Firewall", {
  inboundAllowType: types.optional(types.string, "none"), // "none", "same-gvc", "same-org", "workload-list", "same-gvc-and-workload-list"
  inboundAllowWorkload: types.array(types.string),
});
export interface FirewallInternalSpecMobx extends Instance<typeof FirewallInternalSpecModel> {}

const FirewallSpecModel = types.model("FirewallSpec", {
  external: types.optional(FirewallExternalSpecModel, () => FirewallExternalSpecModel.create()),
  internal: types.optional(FirewallInternalSpecModel, () => FirewallInternalSpecModel.create()),
});
export interface FirewallSpecMobx extends Instance<typeof FirewallSpecModel> {}

export const JobSpecModel = types.model({
  schedule: types.optional(types.string, ""),
  concurrencyPolicy: types.optional(types.string, "Forbid"), // Forbid | Replace
  historyLimit: types.optional(types.number, 5), // between 1 and 10
  restartPolicy: types.optional(types.string, "Never"), // OnFailure | Never
  activeDeadlineSeconds: types.maybe(types.number), // min 1
});
export interface JobSpecMobx extends Instance<typeof JobSpecModel> {}

export const LoadBalancerDirectPortModel = types.model({
  externalPort: types.number, // 22 - 32768
  protocol: types.enumeration(["TCP", "UDP"]),
  containerPort: types.maybe(types.number), // 80 - 65535 ~[8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000]
  scheme: types.maybe(types.enumeration(["http", "tcp", "https", "ws", "wss"])),
});

export const LoadBalancerDirectModel = types.model({
  enabled: types.boolean,
  ports: types.array(LoadBalancerDirectPortModel),
  ipSet: types.maybe(types.string),
});

export const LoadBalancerModel = types.model({
  direct: types.maybe(LoadBalancerDirectModel),
  geoLocation: types.maybe(
    types.model({
      enabled: types.optional(types.boolean, false),
      headers: types.maybe(
        types.model({
          asn: types.maybe(types.string),
          city: types.maybe(types.string),
          country: types.maybe(types.string),
          region: types.maybe(types.string),
        }),
      ),
    }),
  ),
});

// export const ExtrasModel = types.model({
//   // Extra kubernetes modifications. Only used for BYOK.`
//   affinity: types.maybe(types.frozen()),
//   tolerations: types.maybe(types.array(types.frozen())),
//   topologySpreadConstraints: types.maybe(types.array(types.frozen())),
// });

const WorkloadSpecModel = types
  .model("WorkloadSpec", {
    type: types.optional(types.string, "serverless"), // serverless | standard | cron | stateful
    identityLink: types.maybe(types.string),
    containers: types.array(ContainerSpecModel),
    firewallConfig: types.optional(FirewallSpecModel, () => FirewallSpecModel.create()),
    defaultOptions: types.maybe(DefaultOptionsModel),
    localOptions: types.array(LocalOptionsModel),
    job: types.maybe(JobSpecModel),
    rolloutOptions: types.maybe(RolloutOptionsModel),
    securityOptions: types.maybe(SecurityOptionsModel),
    supportDynamicTags: types.optional(types.boolean, false),
    sidecar: types.maybe(
      types.model({
        envoy: types.maybe(types.frozen()),
      }),
    ),
    loadBalancer: types.maybe(LoadBalancerModel),
    extras: types.maybe(types.frozen()),
  })
  .views((self) => {
    function containerByName(name: string) {
      return workloadHelpers.getContainerByName(self, name);
    }
    return { containerByName };
  })
  .actions((self) => ({
    afterCreate() {
      if (self.type !== "standard") {
        return;
      }
      if (self.defaultOptions?.autoscaling?.metric === "concurrency") {
        self.defaultOptions.autoscaling.setMetric("rps");
      }
      for (let localOption of self.localOptions) {
        if (localOption.autoscaling?.metric === "concurrency") {
          localOption.autoscaling.setMetric("rps");
        }
      }
    },
  }));
export interface WorkloadSpecMobx extends Instance<typeof WorkloadSpecModel> {}

export const WorkloadModel = types
  .compose(
    "Workload",
    BaseModel,
    types.model({
      spec: WorkloadSpecModel,
      status: types.optional(WorkloadStatusModel, () => WorkloadStatusModel.create()),
      latestVersion: types.optional(types.number, 1),
    }),
  )
  .views((self) => ({
    get type() {
      return workloadHelpers.getType(self);
    },
    get gvc() {
      return workloadHelpers.getGvc(self);
    },
    get deploymentLink() {
      return workloadHelpers.getDeploymentLink(self);
    },
    get containerNames(): string[] {
      return workloadHelpers.getContainerNames(self);
    },
    get isServerless() {
      return workloadHelpers.getIsServerless(self);
    },
    get isStandard() {
      return workloadHelpers.getIsStandard(self);
    },
    get isCron() {
      return workloadHelpers.getIsCron(self);
    },
    get isStateful() {
      return workloadHelpers.getIsStateful(self);
    },
  }))
  .actions((self) => {
    const updateVersion = flow(function* () {
      if (isAlive(self)) {
        const { data } = yield automatedRequest({ url: self.selfLink });
        if (isAlive(self) && self.latestVersion !== data.version) {
          self.latestVersion = data.version;
        }
      }
    });
    const updateStatus: (automated: boolean) => Promise<void> = flow(function* (automated) {
      if (isAlive(self)) {
        const requestToAwait = automated ? automatedRequest({ url: self.selfLink }) : request({ url: self.selfLink });
        const { data } = yield requestToAwait;
        if (isAlive(self)) {
          applySnapshot(self.status, data.status);
          self.latestVersion = data.version;
        }
      }
    });
    const fetch: () => Promise<void> = flow(function* () {
      const { data } = yield request({ url: self.selfLink });
      applySnapshot(self, Object.assign(data));
      self.latestVersion = data.version;
    });
    return { updateVersion, updateStatus, fetch };
  })
  .actions((self) => {
    const patch: (body: any) => Promise<void> = flow(function* (body: any) {
      body = Object.assign({}, body, { id: self.id, version: self.version });
      delete body.events;
      yield request({ method: "patch", url: self.selfLink, body });
      yield self.fetch();
    });
    return { patch };
  })
  .actions((self) => {
    const forceRedeployment: () => Promise<void> = flow(function* () {
      let tags = Object.assign({}, self.tags);
      tags["cpln/deployTimestamp"] = new Date().toISOString();
      yield self.patch({ "$replace/tags": tags });
    });
    return { forceRedeployment };
  })
  .actions((self) => {
    const deleteContainer: (containerName: string) => Promise<void> = flow(function* (containerName: string) {
      const body: any = {
        spec: {
          "$drop/containers": [containerName],
        },
      };
      yield self.patch(body);
    });
    return { deleteContainer };
  })
  .views((self) => ({
    get containers() {
      return workloadHelpers.getContainers(self as any);
    },
    get suspendType(): "suspended" | "partiallySuspended" | "notSuspended" {
      return workloadHelpers.getSuspendType(self);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.latestVersion = self.version;
    },
  }));

export interface WorkloadMobx extends Instance<typeof WorkloadModel> {}

export const firewallRequirementsForVolumes = {
  "s3://": ["*.amazonaws.com"],
  "gs://": ["*.googleapis.com"],
  "azureblob://": ["*.blob.core.windows.net", "*.azure.com"],
  "azurefs://": ["*.file.core.windows.net", "*.azure.com"],
};

export const envCplnReferenceOptions = [
  "metadata.name",
  "metadata.namespace",
  "spec.nodeName",
  "status.hostIP",
  "status.podIP",
  "status.podIPs",
];
