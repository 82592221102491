import * as React from "react";
import { observer } from "mobx-react-lite";
import { request } from "../../../services/cpln";
import { Table } from "../../../newcomponents/table/table";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { NGChip } from "../../../newcomponents/chip";
import { Issue } from "../../../schema/types/issue";

const IssueDrawerContentRaw = () => {
  const [issues, setIssues] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = async () => {
    setIsLoading(true);
    try {
      const res = await request({
        url: "https://sentry.services.controlplane.site/issue",
        service: "self",
        method: "post",
        body: {
          accountId: ConsoleContext.accountId,
          org: ConsoleContext.org,
        },
      });
      const _issues = res.data.map((i) => ({
        id: i.id,
        author: i.metadata.name,
        message: i.metadata.message,
        status: i.status,
      }));
      setIssues(_issues);
      console.log("Issues reported by user:", res.data);
    } catch (error) {
      console.error("Error fetching issues:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Table<Issue>
        data={issues}
        tableId={"issue"}
        isLoading={isLoading}
        hideSettings
        columns={[
          {
            id: "author",
            label: "Author",
          },
          {
            id: "message",
            label: "Message",
          },
          {
            id: "status",
            label: "Status",
            cell: (props) => {
              const status = props.row.original.status || "";
              const statusLabel = status.charAt(0).toUpperCase() + status.slice(1);
              return (
                <div className="py-2">
                  <NGChip
                    size="small"
                    variant={
                      status === "pending"
                        ? "warning"
                        : status === "ignored" || status === "muted" || status === "unresolved"
                        ? "info" // was secondary
                        : status === "resolved"
                        ? "success"
                        : "info"
                    }
                    label={statusLabel}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export const IssuesDrawerContent = observer(IssueDrawerContentRaw);
