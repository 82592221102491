import * as React from "react";
import jsYaml from "js-yaml";
import { Modal, notification } from "antd";
import { linksOf, request, RequestParams, TerraformExporterResponse } from "../../services/cpln";
import { clearItem, k8sKeySort, toSortedJSON } from "../../services/utils";
import { Apply } from "../../mobxStores/apply/apply";
import { Copy, Download, Edit } from "react-feather";
import { Kind } from "../../mst/base";
import { NGButton } from "../../newcomponents/button/Button";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { TerraformContext } from "../../mobxStores/terraform/terraformContext";
import { CodeEditor, CodeEditorLanguages } from "../../pages/group/identityMatcher/codeEditor";
import { useLocation, useNavigate } from "react-router-dom";
import { convertAllLinksToRelative, ngParseLink } from "../../utils/linkParser/linkParser";
import { NGSwitch } from "../../newcomponents/switch";
import { IS_LOCAL } from "../../envVariables";
import { Loader } from "../layout/loader";
import { NGLabel } from "../../newcomponents/text/label";
import { InfoTooltip } from "../InfoTooltip";
import { CodeSnippet } from "../generic/codeSnippet/codeSnippet";
import { CustomScrollbars } from "../Scrollbar";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Tooltip } from "../Tooltip";
import { WhatIsSlim } from "../../utils/constants";
import { Theme } from "../../mobxStores/uiData/theme";
import { User } from "../../mobxStores/user/user";

const STORAGE_KEY_VIEW_MODAL_FONT_SIZE = "view-modal-font-size";
const STORAGE_KEY_VIEW_MODAL_SELECTED_FORMAT = "view-modal-selected-format";

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  object?: any;
  kind?: string;
  link?: string;
  onClose: any;
  visible: boolean;
  title: string;
  filename: string;
  initialFormat?: CodeEditorLanguages;
  noSlim?: boolean;
}

export const ViewModal: React.FC<Props> = React.memo(
  function ViewModal({
    title,
    object,
    kind,
    link,
    onClose,
    visible,
    filename,
    initialFormat = "yaml",
    noSlim = false,
  }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const fontSizeOptions = [12, 16, 20, 24, 32, 44];
    const isKindSupportedByTerraform: boolean =
      (kind && TerraformContext.supportedKinds.includes(kind as Kind)) || false;
    const headerButtonsClassNames: string = `border rounded ngfocus view-modal-button flex items-center ${
      Theme.theme == "dark" ? "dark" : ""
    }`;
    const headerButtonsStyle: React.CSSProperties = { padding: "5px 8px" };
    const safeInitialFormat: CodeEditorLanguages = initialFormat == "hcl" ? "yaml" : initialFormat;

    // Define format options
    const formatOptions: OptionType[] = [
      { value: "yaml", label: "YAML" },
      { value: "json", label: "JSON" },
    ];

    // Show the Terraform option only if the kind is supported
    if (isKindSupportedByTerraform) {
      formatOptions.push({ value: "hcl", label: "Terraform" });
    }

    // ANCHOR - States
    const [data, setData] = React.useState<any>(null);
    const [terraformData, setTerraformData] = React.useState<TerraformExporterResponse | null>(null);
    const [format, _setFormat] = React.useState<CodeEditorLanguages>(getDefaultFormat);
    const [slim, setSlim] = React.useState(localStorage.getItem("CPLN_DEBUG") === "true" ? false : !noSlim);
    const [includeProviderSnippet, setIncludeProviderSnippet] = React.useState(false);
    const [fontSize, _setFontSize] = React.useState<number>(getDefaultFontSize);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [failureMessage, setFailureMessage] = React.useState<string | null>(null);
    const [failedFormat, setFailedFormat] = React.useState<string | null>(null);

    // ANCHOR - Effects
    React.useEffect(() => {
      prepare();
    }, []);

    // ANCHOR - Functions
    async function prepare() {
      // If the locally saved format is hcl, fetch and set terraform data
      if (format == "hcl") {
        setIsLoading(true);

        try {
          setTerraformData(await fetchTerraformData());
        } catch (e) {
          setFormat(safeInitialFormat);
        }

        setIsLoading(false);
      }

      // Display specified object as is
      if (object) {
        setData(toSortedJSON(object));
        clearError();
        return;
      }

      // Fetch the item using the specified self link
      setIsLoading(true);

      try {
        let res: any;
        if (kind === "secret") {
          try {
            const { data } = await request({ url: link! + "/-reveal" });
            res = data;
          } catch (e) {
            let errorMessage = e?.response?.data?.message;
            if (!errorMessage) {
              errorMessage = e.message;
            }

            if (e?.response?.status != 403) {
              notification.warning({ message: "Failed to Reveal", description: errorMessage });
            }

            const { data: dataCatch } = await request({ url: link! });
            res = dataCatch;
          }
        } else {
          const { data: noSecretData } = await request({ url: link! });
          res = noSecretData;
        }
        setData(toSortedJSON(res));
        clearError();
      } catch (e) {
        setError(format.toUpperCase(), e.message);
      }

      setIsLoading(false);
    }

    async function setFormatAsync(value: string): Promise<void> {
      // Fetch terraform data for a first time, if specified
      if (value == "hcl" && !terraformData) {
        setIsLoading(true);

        try {
          setTerraformData(await fetchTerraformData());
        } catch (e) {
          setError("Terraform", `Unable to fetch terraform resource, error: ${e.message}`);

          // Default next view to YAML
          value = safeInitialFormat;
        }

        setIsLoading(false);
      }

      // Set format accordingly
      setFormat(value);
    }

    async function fetchTerraformData(): Promise<TerraformExporterResponse> {
      let selfLink: string | undefined = link;
      const queryParameters: string = "import=true";

      // If link is undefined, attempt to get the self link from the specified object parameter
      if (!selfLink && object && object.links && Array.isArray(object.links)) {
        selfLink = linksOf(object.links).self!;
      }

      // Determine request parameters
      let requestParams: RequestParams;

      if (selfLink) {
        requestParams = {
          service: "terraform-exporter",
          url: `${selfLink}?${queryParameters}`,
        };
      } else {
        requestParams = {
          service: "terraform-exporter",
          method: "post",
          url: `/?${queryParameters}&org=${ConsoleContext.org}`,
          body: object,
        };
      }

      // Fetch the terraform resource
      const data: string | TerraformExporterResponse = (await request(requestParams)).data;

      if (typeof data === "string") {
        return {
          imports: [],
          resources: [data],
          providerSnippet: "",
        };
      }

      return data;
    }

    function convertDataToString(isSlim: boolean, isEditAndApply: boolean = false): string {
      let output: string = "";
      let _format: string = format;

      if (data !== null) {
        const _data: any = convertAllLinksToRelative(JSON.parse(JSON.stringify(data)));

        // Convert data to slim
        if (isSlim) {
          clearItem(_data);
        }

        // Ignore selected format in edit & apply, use YAML instead
        if (isEditAndApply && _format != "json" && _format != "yaml") {
          _format = "yaml";
        }

        switch (_format) {
          case "json":
            output = JSON.stringify(_data, null, 2);
            break;
          case "hcl":
            output = "";

            if (terraformData) {
              if (includeProviderSnippet) {
                output += terraformData.providerSnippet + "\n";
              }

              output += terraformData.resources.join("\n\n");
            }
            break;
          default:
            // Yaml is the default format
            output = jsYaml.dump(_data, { indent: 2, noRefs: true, sortKeys: k8sKeySort });
            break;
        }
      }

      return output;
    }

    async function onCopyToClipboard(): Promise<void> {
      try {
        navigator.clipboard.writeText(convertDataToString(slim));
        notification.success({
          message: "Copied to clipboard",
        });
      } catch (e) {
        notification.error({
          message: `Unable to copy to clipboard, error: ${e.message}`,
        });
      }
    }

    function onDownload(): void {
      let extension: string = format == "hcl" ? "tf" : format;
      const a: HTMLAnchorElement = document.createElement("a");

      // Set style
      a.style.display = "none";
      a.classList.add("cpln-temp-a");

      // Set the filename and extension for the file to be downloaded
      a.download = `${filename}.${extension}`;

      try {
        // Create a Blob object containing the file data, with the specified file type based on the extension
        const file = new Blob([convertDataToString(slim)], {
          type: `text/${extension}`,
        });

        // Create a URL for the Blob object and assign it to element to enable the download
        a.href = URL.createObjectURL(file);

        // Trigger the click event to download the data as a file
        a.click();
      } catch (e) {
        notification.error({ message: `Unable to download item, error: ${e.message}` });
      }
    }

    function onEditApply(): void {
      Apply.setInitialContent(convertDataToString(true, true));
      Apply.setPrevPath(pathname);
      const { org, gvc } = ngParseLink(pathname, { useInputCtx: true });
      Apply.setOriginalResource({ kind: data.kind, name: data.name, org, gvc });
      navigate(`/console/apply`);
      onClose();
    }

    function adjustFontSize(isIncrease: boolean): void {
      const index = fontSizeOptions.indexOf(fontSize);
      setFontSize(fontSizeOptions[index + (isIncrease ? 1 : -1)]);
    }

    function getDefaultFormat(): CodeEditorLanguages {
      let defaultFormat: string | null = localStorage.getItem(STORAGE_KEY_VIEW_MODAL_SELECTED_FORMAT);

      if (!defaultFormat) {
        defaultFormat = initialFormat;
      }

      return defaultFormat as CodeEditorLanguages;
    }

    function getDefaultFontSize(): number {
      const savedState: string | null = localStorage.getItem(STORAGE_KEY_VIEW_MODAL_FONT_SIZE);
      return savedState ? Number(savedState) : fontSizeOptions[1];
    }

    function clearError(): void {
      setFailedFormat(null);
      setFailureMessage(null);
    }

    function setError(format: string, message: string): void {
      setFailedFormat(format);
      setFailureMessage(message);
      console.error(message);
    }

    function setFormat(value: string): void {
      localStorage.setItem(STORAGE_KEY_VIEW_MODAL_SELECTED_FORMAT, value);
      _setFormat(value as CodeEditorLanguages);
    }

    function setFontSize(value: number): void {
      _setFontSize(value);
      localStorage.setItem(STORAGE_KEY_VIEW_MODAL_FONT_SIZE, value.toString());
    }

    // ANCHOR - Render
    if (!object && !kind && !link) {
      console.error(
        "Error in TextModal: At least one of the following is required - an object, or a combination of 'kind' and 'link'.",
      );
      notification.error({
        message: "We encountered an unexpected error while attempting to display the item, please try again.",
      });

      return null;
    }

    if (failureMessage) {
      return (
        <Modal
          title={`Error with ${failedFormat}`}
          open={true}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={onClose}>
                Close
              </NGButton>
              <NGButton variant="primary" onClick={prepare} loading={isLoading} disabled={isLoading}>
                Try Again
              </NGButton>
            </div>
          }
        >
          <div>
            We encountered an issue while attempting to display the item in <b>{failedFormat}</b> format, please try
            again.
          </div>
          {!IS_LOCAL ? <div>{failureMessage}</div> : null}
        </Modal>
      );
    }

    return (
      <Modal
        className="modal-parent-custom"
        onCancel={onClose}
        title={
          <div className="flex items-start justify-between">
            <div className="mr-4 text-lg">{title}</div>
            <div className="flex items-center gap-2 pr-12 text-sm">
              <button
                autoFocus
                onClick={onCopyToClipboard}
                className={headerButtonsClassNames}
                style={headerButtonsStyle}
              >
                <Copy className="inline-block feather-icon" />
                <div className="ml-1">Copy</div>
              </button>
              <button className={headerButtonsClassNames} onClick={onDownload} style={headerButtonsStyle}>
                <Download className="inline-block feather-icon" />
                <div className="ml-1">Download</div>
              </button>
              {!kind || kind === "deployment" ? null : (
                <button
                  autoFocus
                  disabled={format == "hcl"}
                  onClick={onEditApply}
                  className={headerButtonsClassNames}
                  style={headerButtonsStyle}
                >
                  <Edit className="inline-block feather-icon" />
                  <div className="ml-1 whitespace-nowrap">Edit & Apply</div>
                </button>
              )}
              <NGSelect
                className="border rounded"
                value={format}
                options={formatOptions}
                onChange={setFormatAsync}
                style={{ width: 120 }}
                size="normal"
              />
              {noSlim ? null : (
                <Tooltip title={WhatIsSlim} placement="bottomLeft">
                  <div className="flex items-center gap-2">
                    <NGSwitch
                      value={slim && format != "hcl"}
                      isDisabled={format === "hcl"}
                      onChange={(checked) => setSlim(checked)}
                    >
                      <span>Slim</span>
                    </NGSwitch>
                  </div>
                </Tooltip>
              )}
              <div className="flex items-center gap-1">
                <button
                  className={`border rounded ngfocus view-modal-button mr-1 ${
                    fontSize === fontSizeOptions[0] ? "bg-input-hover cursor-not-allowed" : ""
                  }`}
                  style={{ fontSize: 20, ...headerButtonsStyle }}
                  disabled={fontSize === fontSizeOptions[0]}
                  onClick={() => {
                    adjustFontSize(false);
                  }}
                >
                  -
                </button>
                <div style={{ fontSize: 8 }}>A</div>
                <div style={{ fontSize: 12 }}>A</div>
                <div style={{ fontSize: 20 }}>A</div>
                <button
                  className={`border rounded ngfocus view-modal-button ml-1 ${
                    fontSize === fontSizeOptions[fontSizeOptions.length - 1] ? "bg-input-hover cursor-not-allowed" : ""
                  }`}
                  style={{ fontSize: 20, ...headerButtonsStyle }}
                  disabled={fontSize === fontSizeOptions[fontSizeOptions.length - 1]}
                  onClick={() => {
                    adjustFontSize(true);
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        }
        open={visible}
        styles={{ body: { height: "75vh", padding: 0 } }}
        width={1000}
        footer=""
      >
        {isLoading ? (
          <Loader reason="loading view modal content" />
        ) : (
          <div className="flex flex-col h-full">
            {format === "hcl" ? (
              <div className="flex flex-col gap-3">
                {/* Terraform Import */}
                {terraformData && terraformData.imports.length > 0 && link ? (
                  <div>
                    {/* Import Command Label */}
                    <div className="flex items-center">
                      <NGLabel>Terraform Import Command</NGLabel>
                      <InfoTooltip
                        title={
                          <p>
                            <span>
                              To prevent Terraform from attempting to create this existing resource, import it into your
                              Terraform state. Learn more about the{" "}
                            </span>
                            <a
                              className="ngfocus color-link font-semibold"
                              target="_blank"
                              href={`https://developer.hashicorp.com/terraform/cli/commands/import`}
                            >
                              Terraform Import Command
                            </a>
                            <span> and how it works with your state.</span>
                          </p>
                        }
                        placement="rightTop"
                        arrow={{ pointAtCenter: true }}
                      />
                    </div>

                    {/* Command Snippet */}
                    <CodeSnippet code={terraformData.imports.join("\n")} className="mt-2 border" />
                  </div>
                ) : null}

                {/* Code Editor Label */}
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <NGLabel>Terraform Resource</NGLabel>
                    <InfoTooltip
                      title={
                        <p>
                          <span>
                            Terraform is an infrastructure-as-code tool. Use this configuration to manage this resource
                            within your Terraform project. Visit the{" "}
                          </span>
                          <a
                            className="ngfocus color-link font-semibold"
                            target="_blank"
                            href={`https://registry.terraform.io/providers/controlplane-com/cpln/latest/docs`}
                          >
                            Control Plane Terraform documentation
                          </a>
                          <span> page to learn more about using our Terraform provider.</span>
                        </p>
                      }
                      placement="rightTop"
                      arrow={{ pointAtCenter: true }}
                    />
                  </div>
                  <NGSwitch value={includeProviderSnippet} onChange={(checked) => setIncludeProviderSnippet(checked)}>
                    <span>Include Provider Snippet</span>
                  </NGSwitch>
                </div>
              </div>
            ) : null}
            <CustomScrollbars className="h-full border rounded px-2 py-2">
              <CodeEditor
                value={convertDataToString(slim)}
                setValue={() => {}}
                language={format}
                minHeight="100%"
                noBorder
                options={{
                  style: {
                    height: "100%",
                    fontSize: fontSize,
                  },
                  basicSetup: {
                    highlightActiveLine: false,
                    highlightActiveLineGutter: false,
                    lineNumbers: true,
                    autocompletion: false,
                    foldGutter: false,
                  },
                  readOnly: true,
                }}
              />
            </CustomScrollbars>
          </div>
        )}
      </Modal>
    );
  },
  (prevProps, nextProps) => {
    const {
      title: prevTitle,
      kind: prevKind,
      link: prevLink,
      onClose: prevOnClose,
      visible: prevVisible,
      filename: prevfilename,
      initialFormat: previnitialFormat,
      object: prevObject,
    } = prevProps;
    const {
      title: nextTitle,
      kind: nextKind,
      link: nextLink,
      onClose: nextOnClose,
      visible: nextVisible,
      filename: nextfilename,
      initialFormat: nextinitialFormat,
      object: nextObject,
    } = nextProps;
    if (
      prevTitle !== nextTitle ||
      prevKind !== nextKind ||
      prevLink !== nextLink ||
      prevVisible !== nextVisible ||
      prevfilename !== nextfilename ||
      previnitialFormat !== nextinitialFormat
    ) {
      return false;
    }
    // object check
    return true;
  },
);
