import * as React from "react";
import { NGCombobox, NGComboboxProps } from "./ngcombobox";
import { observer } from "mobx-react-lite";
import { Kind } from "../../mst/base";
import { FilterTerm, kindQuery } from "../../mst/query";
import { homeLink, indefiniteArticleOfKind, linksOf, nameOfKind, request } from "../../services/cpln";
import { Loader } from "react-feather";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { gvcScopedKinds } from "../../utils/kinds";
import { useDebounce } from "../../components/table/useDebounce";
import { v4 as uuidv4 } from "uuid";

export interface NGKindSelectProps<T>
  extends Omit<NGComboboxProps<T>, "onOptionsScrolled" | "options" | "filterOptions" | "onChange"> {
  kind: Kind;
  fetchAll?: boolean;
  queries?: FilterTerm[];
  optionValueAs?: "relative" | "absolute" | "name";
  filterOptions?: (item: any) => boolean;
  onChange: (value: string, item: any) => void;
  autocompleteId?: string;
}

function NGKindSelectRaw<T>({
  kind,
  queries = [],
  optionValueAs = "name",
  filterOptions = () => true,
  value,
  placeholder,
  getOptionValue,
  onChange,
  fetchAll,
  ...comboboxProps
}: NGKindSelectProps<T>) {
  fetchAll = gvcScopedKinds.includes(kind);

  const optionValueAsFns = {
    relative: (item: any) => {
      const itemLink = linksOf(item).self!;
      const { gvcRelative, relative, isGVCScoped } = ngParseLink(itemLink, { kind: kind });
      return isGVCScoped ? gvcRelative : relative;
    },
    absolute: (item: any) => linksOf(item).self!,
    name: (item: any) => item.name,
  };

  const [debouncedValue, setDebouncedValue] = useDebounce(value, 140);
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const [nextLink, setNextLink] = React.useState<string | undefined>(undefined);
  const [fuzzyItems, setFuzzyItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    fetchInitialItems();
  }, [queries.length]);

  React.useEffect(() => {
    setDebouncedValue(value);
  }, [value]);

  async function fetchInitialItems() {
    try {
      setIsLoading(true);
      const method = queries.length > 0 ? "post" : "get";
      const url = homeLink(kind) + (queries.length > 0 ? "/-query" : "");
      const body = queries.length > 0 ? kindQuery(kind, queries) : undefined;
      const { data } = await request({ method: method, url: url, body: body });
      setItems(data.items);
      setNextLink(linksOf(data).next);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (fetchAll) {
      fetchPage();
    }
  }, [nextLink]);

  async function fetchPage() {
    if (!nextLink) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await request({ method: "get", url: nextLink });
      setItems((items) => {
        return [...items, ...data.items];
      });
      setNextLink(linksOf(data).next);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    fetchFuzzyItems();
  }, [debouncedValue]);

  const fuzzyItemsRequestIdRef = React.useRef("");
  async function fetchFuzzyItems() {
    const requestId = uuidv4();
    fuzzyItemsRequestIdRef.current = requestId;
    if (!debouncedValue) {
      setFuzzyItems([]);
    }
    try {
      setIsLoading(true);
      const method = "post";
      let nextLink: string | undefined = homeLink(kind) + "/-query";
      const body = kindQuery(kind, queries, debouncedValue);
      let _items: any[] = [];
      while (nextLink) {
        const { data } = await request({ method: method, url: nextLink, body: body });
        nextLink = linksOf(data).next;
        _items = _items.concat(data.items);
      }
      if (fuzzyItemsRequestIdRef.current !== requestId) {
        return;
      }
      setFuzzyItems(_items);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  function onOptionsScrolled() {
    if (isLoading) {
      return;
    }
    if (!nextLink) {
      return;
    }

    fetchPage();
  }

  let processedGetOptionValue = getOptionValue!;
  if (!getOptionValue) {
    processedGetOptionValue = optionValueAsFns[optionValueAs]!;
  }

  function localOnChange(value: string) {
    const item = items.find((item) => {
      const itemValue = processedGetOptionValue(item);
      if (itemValue === value) {
        return true;
      }
      return false;
    });
    onChange(value, item);
  }

  let itemsProcessed = items.filter((item) => filterOptions(item));
  if (fetchAll) {
    itemsProcessed = itemsProcessed.toSorted((a, b) => {
      if (a.name < b.name) return -1;
      if (b.name < a.name) return 1;
      return 0;
    });
  }

  return (
    <>
      <NGCombobox
        options={itemsProcessed}
        getOptionLabel={(item: any) => item.name}
        onOptionsScrolled={onOptionsScrolled}
        placeholder={placeholder || `Select ${indefiniteArticleOfKind(kind)} ${nameOfKind(kind)}`}
        getOptionValue={processedGetOptionValue}
        filteredOptions={value ? fuzzyItems : undefined}
        showNoItems={false}
        value={value}
        onChange={(value) => localOnChange(value)}
        buttons={
          isLoading
            ? [
                {
                  render() {
                    return <Loader className="h-4" />;
                  },
                  onClick() {},
                  title: "Loading",
                },
              ]
            : []
        }
        {...comboboxProps}
      />
    </>
  );
}

export const NGKindSelect = observer(NGKindSelectRaw);
