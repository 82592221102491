import * as React from "react";
import { observer } from "mobx-react-lite";
import { NGLabel } from "../../../../../newcomponents/text/label";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { DryRunAlert } from "../../../dryRunAlert";
import { Mk8sDraftProviderTritonLoadBalancerModelMobx } from "../../../../../mst/stores/mk8s.draft.provider.triton.loadBalancer";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { homeLink, request } from "../../../../../services/cpln";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGInput } from "../../../../../newcomponents/input/input";
import { getDryRunErrorText } from "../../../getDryRunErrorText";
import { NGTextArea } from "../../../../../newcomponents/textarea";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
  lb: Mk8sDraftProviderTritonLoadBalancerModelMobx;
}
const TritonLoadBalancerRaw: React.FC<Props> = ({ mk8sDraft, lb }) => {
  const formData = useNGFormContext();

  const formPrefix = `triton.loadBalancer.`;

  const [browserKey, setBrowserKey] = React.useState("");

  const privateNetworkIdsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.loadBalancer.manual.privateNetworkIds`,
    paths: [
      "spec.provider.triton.loadBalancer.manual",
      ...lb.manual.privateNetworkIds.items.map(
        (_, idx) => `spec.provider.triton.loadBalancer.manual.privateNetworkIds[${idx}]`,
      ),
    ],
  });

  const metadataDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.loadBalancer.manual.metadata`,
    paths: lb.manual.metadata.items.map((i) => `spec.provider.triton.loadBalancer.manual.metadata.${i.firstValue}`),
  });

  const tagsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.loadBalancer.manual.tags`,
    paths: lb.manual.tags.items.map((i) => `spec.provider.triton.loadBalancer.manual.tags.${i.firstValue}`),
  });

  return (
    <>
      <div className="p-4 mb-4 border rounded">
        <div className="mb-4">
          <NGLabel size={2}>Load Balancer</NGLabel>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${formPrefix}method`}
            label={lb.method.label}
            value={lb.method.value}
            onChange={lb.method.setValue}
            as={"select"}
            options={lb.method.options}
          />
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.triton.loadBalancer`}
          />
        </div>

        {lb.method.value === "gateway" ? (
          <>
            <div className="mt-2">
              <DryRunAlert
                key={mk8sDryRun.key}
                canShow={true}
                dryRunResponse={mk8sDryRun.response}
                path={`spec.provider.triton.loadBalancer.gateway`}
              />
            </div>
          </>
        ) : null}
        {lb.method.value === "manual" ? (
          <>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.packageId`}
                label={lb.manual.packageId.label}
                value={lb.manual.packageId.value}
                onChange={lb.manual.packageId.setValue}
                required={lb.manual.packageId.isRequired}
                error={lb.manual.packageId.error}
                innerButtons={[
                  {
                    title: "Browse",
                    onClick: () => {
                      setBrowserKey("packages");
                      formData.set(`${formPrefix}manual.packageId`, { touched: true });
                    },
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.packageId.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.packageId`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.imageId`}
                label={lb.manual.imageId.label}
                value={lb.manual.imageId.value}
                onChange={lb.manual.imageId.setValue}
                required={lb.manual.imageId.isRequired}
                error={lb.manual.imageId.error}
                innerButtons={[
                  {
                    title: "Browse",
                    onClick: () => {
                      setBrowserKey("lb_images");
                      formData.set(`${formPrefix}manual.imageId`, { touched: true });
                    },
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.imageId.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.imageId`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.publicNetworkId`}
                label={lb.manual.publicNetworkId.label}
                value={lb.manual.publicNetworkId.value}
                onChange={lb.manual.publicNetworkId.setValue}
                required={lb.manual.publicNetworkId.isRequired}
                error={lb.manual.publicNetworkId.error}
                innerButtons={[
                  {
                    title: "Browse",
                    onClick: () => {
                      setBrowserKey("public-networks");
                      formData.set(`${formPrefix}manual.publicNetworkId`, { touched: true });
                    },
                    render: () => <MoreHorizontal />,
                  },
                ]}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.publicNetworkId.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.publicNetworkId`}
                />
              </div>
            </div>
            <NGInputListMst
              data={lb.manual.privateNetworkIds}
              styles={{ container: { width: 450 } }}
              labelRender={() => {
                return (
                  <NGButton
                    className="ml-1"
                    size={"small"}
                    variant={"secondary"}
                    onClick={() => setBrowserKey("private-networks")}
                  >
                    Browse
                  </NGButton>
                );
              }}
              firstInput={(i, idx) => {
                let invalid = false;
                if (i.firstValue && privateNetworkIdsDryRun.message.includes(`[${idx}]`)) {
                  invalid = true;
                }
                return (
                  <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />
                );
              }}
              label="Private Network Ids"
              invalid={privateNetworkIdsDryRun.severity === "error"}
              error={privateNetworkIdsDryRun.severity === "error" ? privateNetworkIdsDryRun.message : ""}
              warning={privateNetworkIdsDryRun.severity === "error" ? "" : privateNetworkIdsDryRun.message}
            />
            <NGInputListMst
              data={lb.manual.metadata}
              styles={{ header: { width: 450 }, container: { width: 450 }, inputsContainer: { alignItems: "start" } }}
              firstLabel="Key"
              firstInput={(i) => {
                let invalid = false;
                if (i.firstValue && metadataDryRun.message.includes(`.${i.firstValue}`)) {
                  invalid = true;
                }
                return (
                  <NGInput
                    required
                    invalid={invalid}
                    value={i.firstValue}
                    onChange={(e) => i.setFirstValue(e.target.value)}
                  />
                );
              }}
              secondLabel="Value"
              secondInput={(i) => {
                return <NGTextArea value={i.secondValue} onChange={(e) => i.setSecondValue(e.target.value)} />;
              }}
              label="Metadata"
              invalid={metadataDryRun.severity === "error"}
              error={metadataDryRun.severity === "error" ? metadataDryRun.message : ""}
              warning={metadataDryRun.severity === "error" ? "" : metadataDryRun.message}
            />
            <NGInputListMst
              data={lb.manual.tags}
              styles={{ container: { width: 450 } }}
              firstLabel="Key"
              firstInput={(i) => {
                let invalid = false;
                if (i.firstValue && tagsDryRun.message.includes(`.${i.firstValue}`)) {
                  invalid = true;
                }
                return (
                  <NGInput
                    required
                    invalid={invalid}
                    value={i.firstValue}
                    onChange={(e) => i.setFirstValue(e.target.value)}
                  />
                );
              }}
              secondLabel="Value"
              secondInput={(i) => {
                let invalid = false;
                if (!i.secondValue) {
                  invalid = true;
                }
                return (
                  <NGInput
                    required
                    invalid={invalid}
                    value={i.secondValue}
                    onChange={(e) => i.setSecondValue(e.target.value)}
                  />
                );
              }}
              label="Tags"
              invalid={tagsDryRun.severity === "error"}
              error={tagsDryRun.severity === "error" ? tagsDryRun.message : ""}
              warning={tagsDryRun.severity === "error" ? "" : tagsDryRun.message}
            />
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.count`}
                label={lb.manual.count.label}
                value={lb.manual.count.value}
                onChange={lb.manual.count.setValue}
                required={lb.manual.count.isRequired}
                error={lb.manual.count.error}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.count.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.count`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.cnsInternalDomain`}
                label={lb.manual.cnsInternalDomain.label}
                value={lb.manual.cnsInternalDomain.value}
                onChange={lb.manual.cnsInternalDomain.setValue}
                required={lb.manual.cnsInternalDomain.isRequired}
                error={lb.manual.cnsInternalDomain.error}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.cnsInternalDomain.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.cnsInternalDomain`}
                />
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <NGFormElement
                name={`${formPrefix}manual.cnsPublicDomain`}
                label={lb.manual.cnsPublicDomain.label}
                value={lb.manual.cnsPublicDomain.value}
                onChange={lb.manual.cnsPublicDomain.setValue}
                required={lb.manual.cnsPublicDomain.isRequired}
                error={lb.manual.cnsPublicDomain.error}
              />
              <div className="mt-6">
                <DryRunAlert
                  key={mk8sDryRun.key}
                  canShow={true}
                  dryRunResponse={mk8sDryRun.response}
                  onFix={lb.manual.cnsPublicDomain.setValue}
                  path={`spec.provider.triton.loadBalancer.manual.cnsPublicDomain`}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      {browserKey === "public-networks" ? (
        <NGProviderBrowser
          title="Browse Triton Public Networks"
          initialValue={lb.manual.publicNetworkId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "public-networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => lb.manual.publicNetworkId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Description", "Subnet", "VLAN Id"]}
          getData={(item: any) => [item.id, item.name, item.id, item.description, item.subnet, item.vlan_id]}
        />
      ) : null}
      {browserKey === "private-networks" ? (
        <NGProviderBrowser
          title="Browse Triton Private Networks"
          initialValue={lb.manual.privateNetworkIds.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "private-networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (lb.manual.privateNetworkIds.items.some((i) => i.firstValue === value)) {
                continue;
              }
              lb.manual.privateNetworkIds.add();
              const i = lb.manual.privateNetworkIds.items[lb.manual.privateNetworkIds.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          sortable
          multipleChoice
          labels={["Name", "Id", "Description", "Subnet", "VLAN Id"]}
          getData={(item: any) => [item.id, item.name, item.id, item.description, item.subnet, item.vlan_id]}
        />
      ) : null}
      {browserKey === "lb_images" ? (
        <NGProviderBrowser
          title="Browse Triton Images"
          initialValue={lb.manual.imageId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "lb_images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => lb.manual.imageId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          sortable
          distinctFilters={["Type", "Brand"]}
          filteredColumns={["Name"]}
          labels={["Name", "Id", "Size", "Type", "Brand", "Version"]}
          getData={(item: any) => [
            item.id,
            item.name,
            item.id,
            [item.image_size || 0, item.image_size ? `${item.image_size / 1024}Gi` : "-"],
            item.type,
            item.requirements?.brand,
            item.version,
          ]}
        />
      ) : null}
      {browserKey === "packages" ? (
        <NGProviderBrowser
          title="Browse Triton Packages"
          initialValue={lb.manual.packageId.value}
          fetchData={async () => {
            // TODOMK8S paginate
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "packages",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => lb.manual.packageId.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Brand", "Version", "Cores", "Memory", "Disk", "Description"]}
          distinctFilters={["Brand", "Cores", "Memory", "Disk"]}
          getData={(item: any) => {
            const memoryGi = item.memory / 1024;
            const diskGi = item.disk / 1024;

            return [
              //
              item.id,
              item.name,
              item.id,
              item.brand,
              item.version,
              item.vcpus,
              [memoryGi, `${memoryGi}Gi`],
              [diskGi, `${diskGi}Gi`],
              item.description,
            ];
          }}
        />
      ) : null}
    </>
  );
};

export const TritonLoadBalancer = observer(TritonLoadBalancerRaw);
