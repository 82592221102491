import { makeObservable, observable } from "mobx";
import { Kind } from "../../schema/types/base";

export interface IResourceForEdit {
  kind: string;
  name: string;
  org: string | null;
  gvc: string | null;
}

class ApplyMobx {
  prevPath: string | undefined = "";
  initialContent: string = "";
  itemLink: string = "";
  originalResource: IResourceForEdit | undefined = undefined;

  constructor() {
    makeObservable(this, {
      prevPath: observable,
      initialContent: observable,
      itemLink: observable,
      originalResource: observable,
    });
  }

  setInitialContent(initialContent: string) {
    this.initialContent = initialContent;
  }

  setPrevPath(prevPath: string | undefined) {
    this.prevPath = prevPath;
  }

  setItemLink(value: string) {
    this.itemLink = value;
  }

  setOriginalResource(resource: IResourceForEdit) {
    this.originalResource = resource;
  }

  clearItemLink() {
    this.itemLink = "";
  }

  clearOriginalResource() {
    this.originalResource = undefined;
  }
}

export const Apply = new ApplyMobx();
