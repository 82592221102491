import { types } from "mobx-state-tree";
import type { Instance } from "mobx-state-tree";
import { WithNameModel, WithLinksModel, WithKindModel, WithLastModifiedModel, WithCreatedModel } from "../base";
import { deploymentHelpers } from "./deployment.helpers";

const DeploymentResourcesModel = types.model({
  replicas: types.optional(types.number, 0),
  replicasReady: types.optional(types.number, 0),
  cpu: types.optional(types.number, 0),
  memory: types.optional(types.number, 0),
});
export interface DeploymentResourcesMobx extends Instance<typeof DeploymentResourcesModel> {}

export const JobExecutionContainerModel = types.model({
  name: types.optional(types.string, ""),
  image: types.optional(types.string, ""),
  ready: types.optional(types.boolean, false),
  message: types.optional(types.string, ""),
  resources: types.model({
    replicas: types.optional(types.number, 0),
    replicasReady: types.optional(types.number, 0),
    cpu: types.optional(types.number, 0),
    memory: types.optional(types.number, 0),
  }),
});
export interface JobExecutionContainerMobx extends Instance<typeof JobExecutionContainerModel> {}

export const JobExecutionModel = types.model({
  workloadVersion: types.optional(types.number, 0),
  status: types.optional(types.string, "pending"), // "successful" | "failed" | "active" | "pending" | "invalid" | 'removed'
  startTime: types.optional(types.string, ""),
  completionTime: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  replica: types.optional(types.string, ""),
  containers: types.map(JobExecutionContainerModel),
});
export interface JobExecutionMobx extends Instance<typeof JobExecutionModel> {}

export const ContainerStatusModel = types.model({
  name: types.identifier,
  image: types.optional(types.string, ""),
  ready: types.optional(types.boolean, false),
  resources: types.optional(DeploymentResourcesModel, () => DeploymentResourcesModel.create()),
  message: types.optional(types.string, ""),
  restarts: types.maybe(
    types.model({
      lastRestartTime: types.maybe(types.string),
      exitCode: types.maybe(types.number),
      reason: types.maybe(types.string),
      count: types.maybe(types.number),
    }),
  ),
});
export interface ContainerStatusMobx extends Instance<typeof ContainerStatusModel> {}

export const DeploymentVersionModel = types.compose(
  "DeploymentStatusVersion",
  WithCreatedModel,
  types.model({
    name: types.optional(types.string, ""),
    workload: types.optional(types.number, 0),
    gvc: types.optional(types.number, 0),
    containers: types.map(ContainerStatusModel),
    ready: types.optional(types.boolean, false),
    message: types.optional(types.string, ""),
  }),
);
export interface DeploymentVersionMobx extends Instance<typeof DeploymentVersionModel> {}

export const DeploymentStatusInternalModel = types.model("DeploymentStatus", {
  syncFailed: types.optional(types.boolean, false),
});
export interface DeploymentStatusInternalMobx extends Instance<typeof DeploymentStatusInternalModel> {}

export const DeploymentStatusModel = types.model("DeploymentStatus", {
  endpoint: types.optional(types.string, ""),
  message: types.optional(types.string, ""),
  remote: types.optional(types.string, ""),
  internal: types.optional(DeploymentStatusInternalModel, () => DeploymentStatusInternalModel.create()),
  ready: types.optional(types.boolean, false),
  deploying: types.optional(types.boolean, false),
  versions: types.array(DeploymentVersionModel),
  jobExecutions: types.array(JobExecutionModel),
  expectedDeploymentVersion: types.optional(types.number, 0),
  lastProcessedVersion: types.optional(types.number, 0),
});
export interface DeploymentStatusMobx extends Instance<typeof DeploymentStatusModel> {}

export const DeploymentModel = types
  .compose(
    "Deployment",
    WithNameModel,
    WithKindModel,
    WithLinksModel,
    WithLastModifiedModel,
    types.model({
      id: types.string, // this is created by the ui artificially
      status: types.optional(DeploymentStatusModel, () => DeploymentStatusModel.create()),
      updateVersion: types.optional(types.number, 0),
    }),
  )
  .views((self) => ({
    get workloadLink() {
      return deploymentHelpers.getWorkloadLink(self);
    },
    get workloadName() {
      return deploymentHelpers.getWorkloadName(self);
    },
  }));
export interface DeploymentMobx extends Instance<typeof DeploymentModel> {}
