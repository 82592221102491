import { types, Instance } from "mobx-state-tree";
import { StringModel } from "../../mobxDataModels/stringModel";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { Mk8sDraftProviderTritonConnectionModel } from "./mk8s.draft.provider.triton.connection";
import {
  Mk8sDraftProviderTritonNodePoolModel,
  Mk8sDraftProviderTritonNodePoolReadonlyModel,
} from "./mk8s.draft.provider.triton.nodePool";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { Mk8sDraftProviderTritonLoadBalancerModel } from "./mk8s.draft.provider.triton.loadBalancer";

export const Mk8sDraftProviderTritonModel = types
  .model({
    connection: types.optional(Mk8sDraftProviderTritonConnectionModel, () =>
      Mk8sDraftProviderTritonConnectionModel.create(),
    ),
    _location: types.optional(types.string, ""),
    location: types.optional(SelectModel, () =>
      SelectModel.create({ label: "Location", options: [], initialValue: "" }),
    ),
    _privateNetworkId: types.optional(types.string, ""),
    privateNetworkId: types.optional(StringModel, () =>
      StringModel.create({ label: "Private Network Id", isRequired: true }),
    ),
    _firewallEnabled: types.optional(types.boolean, false),
    firewallEnabled: types.optional(types.boolean, false),
    _preInstallScript: types.optional(types.string, ""),
    preInstallScript: types.optional(StringModel, () => StringModel.create({ label: "Pre Install Script" })),
    _imageId: types.optional(types.string, ""),
    imageId: types.optional(StringModel, () => StringModel.create({ label: "Image Id", isRequired: true })),
    _sshKeys: types.array(types.string),
    sshKeys: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    _nodePools: types.array(Mk8sDraftProviderTritonNodePoolReadonlyModel),
    nodePools: types.array(Mk8sDraftProviderTritonNodePoolModel),
    autoscaler: types.optional(Mk8sDraftAutoscalerModel, () => Mk8sDraftAutoscalerModel.create()),
    networking: types.optional(Mk8sDraftNetworkingModel, () => Mk8sDraftNetworkingModel.create()),
    loadBalancer: types.optional(Mk8sDraftProviderTritonLoadBalancerModel, () =>
      Mk8sDraftProviderTritonLoadBalancerModel.create(),
    ),
    removed: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setFirewallEnabled(value: boolean) {
      self.firewallEnabled = value;
    },
    reset() {
      self.connection.reset();
      self.location.setInitialValue(self._location);
      self.privateNetworkId.setInitialValue(self._privateNetworkId);
      self.firewallEnabled = self._firewallEnabled;
      self.preInstallScript.setInitialValue(self._preInstallScript);
      self.imageId.setInitialValue(self._imageId);
      self.sshKeys.setInitialItems(self._sshKeys.map((v) => ({ firstValue: v })));
      self.sshKeys.reset();
      self.loadBalancer.reset();

      self.nodePools.clear();
      for (let index in self._nodePools) {
        const _nodePool = self._nodePools[index];

        self.nodePools.push(
          Mk8sDraftProviderTritonNodePoolModel.create({
            index: Number(index),
            _pool: JSON.parse(JSON.stringify(_nodePool)),
          }),
        );
      }
      self.removed = false;

      self.autoscaler.reset();
      self.networking.reset();
    },
    confirm() {
      self.connection.confirm();
      self.location.confirm();
      self._location = self.location.value;
      self.privateNetworkId.confirm();
      self._privateNetworkId = self.privateNetworkId.value;
      self._firewallEnabled = self.firewallEnabled;
      self.preInstallScript.confirm();
      self._preInstallScript = self.preInstallScript.value;
      self.imageId.confirm();
      self._imageId = self.imageId.value;
      self._sshKeys.clear();
      for (const sshKey of self.sshKeys.items.map((i) => i.firstValue)) {
        self._sshKeys.push(sshKey);
      }
      self.sshKeys.confirm();
      self.loadBalancer.confirm();

      self._nodePools.clear();
      for (let nodePool of self.nodePools) {
        nodePool.confirm();
        self._nodePools.push(
          Mk8sDraftProviderTritonNodePoolReadonlyModel.create({
            name: nodePool.name.value,
            labels: Object.entries(nodePool.asObject.labels).map(([key, value]) => ({ key: key, value: value })) as any,
            taints: nodePool.asObject.taints,
            packageId: nodePool.packageId.value,
            overrideImageId: nodePool.overrideImageId.value,
            publicNetworkId: nodePool.publicNetworkId.value,
            privateNetworkIds: nodePool.privateNetworkIds.items.map((i) => i.firstValue),
            tritonTags: Object.entries(nodePool.asObject.tritonTags).map(([key, value]) => ({
              key: key,
              value: value,
            })) as any,
            minSize: String(nodePool.minSize.value).length > 0 ? Number(nodePool.minSize.value) : undefined,
            maxSize: String(nodePool.maxSize.value).length > 0 ? Number(nodePool.maxSize.value) : undefined,
          }),
        );
      }
      self.removed = false;

      self.autoscaler.confirm();
      self.networking.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    addNodePool() {
      let index = 0;
      if (self.nodePools.length > 0) {
        index = self.nodePools[self.nodePools.length - 1].index + 1;
      }
      self.nodePools.push(
        Mk8sDraftProviderTritonNodePoolModel.create({
          index,
          status: "added",
        }),
      );
    },
    removeNodePoolAt(index: number) {
      const node = self.nodePools.find((np) => np.index === index);
      if (!node) {
        return;
      }
      if (node.status === "default") {
        self.removed = true;
      }
      self.nodePools.remove(node);
    },
  }))
  .views((self) => ({
    isNodePoolNameValid(index: number) {
      let res = true;
      const node = self.nodePools.find((np) => np.index === index)!;
      const pools = [...self.nodePools.filter((np) => np.index !== index).slice()];
      if (pools.some((np) => np.name.value === node.name.value)) {
        res = false;
      }
      return res;
    },
    get anyNodePoolNameConflicts() {
      for (const nodePool of self.nodePools) {
        if (
          [...self.nodePools.filter((np) => np.index !== nodePool.index).slice()].some(
            (np) => np.name.value === nodePool.name.value,
          )
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.connection.isDirty) res = true;
      if (self._nodePools.length !== self.nodePools.length) res = true;
      if (self.removed) res = true;
      if (self.nodePools.some((n) => n.isDirty)) res = true;
      if (self.location.isDirty) res = true;
      if (self.privateNetworkId.isDirty) res = true;
      if (self.firewallEnabled !== self._firewallEnabled) res = true;
      if (self.imageId.isDirty) res = true;
      if (self.sshKeys.isDirty) res = true;
      if (self.loadBalancer.isDirty) res = true;
      if (self.autoscaler.isDirty) res = true;
      if (self.networking.isDirty) res = true;
      if (self.preInstallScript.isDirty) res = true;
      return res;
    },
    get dirtyReason() {
      let reason = "";
      if (self.connection.isDirty) reason = self.connection.dirtyReason;
      if (self._nodePools.length !== self.nodePools.length) reason = "node pool length";
      if (self.removed) reason = "removed";
      if (self.nodePools.some((n) => n.isDirty))
        reason = self.nodePools.find((n) => n.isDirty)?.dirtyReason || "node pools has dirty";
      if (self.location.isDirty) reason = "location";
      if (self.privateNetworkId.isDirty) reason = "privateNetworkId";
      if (self.firewallEnabled !== self._firewallEnabled) reason = "firewallEnabled";
      if (self.imageId.isDirty) reason = self.imageId.isDirtyReason;
      if (self.sshKeys.isDirty) reason = "sshKeys";
      if (self.loadBalancer.isDirty) reason = "loadBalancer";
      if (self.autoscaler.isDirty) reason = self.autoscaler.dirtyReason;
      if (self.networking.isDirty) reason = self.networking.dirtyReason;
      if (self.preInstallScript.isDirty) reason = "preInstallScript";
      return reason;
    },
    get isValid() {
      let res = true;
      if (self.anyNodePoolNameConflicts) res = false;
      if (self.nodePools.some((n) => !n.isValid)) res = false;
      if (!self.connection.isValid) res = false;
      if (!self.location.value) res = false;
      if (!self.privateNetworkId.isValid) res = false;
      if (!self.imageId.isValid) res = false;
      // TODO can validate sshkeys
      if (!self.loadBalancer.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.preInstallScript.isValid) res = false;
      return res;
    },
    get invalidReason() {
      let reason = "";
      if (self.anyNodePoolNameConflicts) reason = "node pool name conflict";
      if (self.nodePools.some((n) => !n.isValid)) reason = self.nodePools.find((n) => !n.isValid)?.invalidReason || "";
      if (!self.connection.isValid) reason = "connection";
      if (!self.location.value) reason = "location";
      if (!self.privateNetworkId.isValid) reason = "privateNetworkId";
      if (!self.imageId.isValid) reason = "imageId";
      if (!self.loadBalancer.isValid) reason = "loadBalancer";
      if (!self.autoscaler.isValid) reason = self.autoscaler.invalidReason;
      if (!self.networking.isValid) reason = self.networking.invalidReason;
      if (!self.preInstallScript.isValid) reason = "preInstallScript";
      return reason;
    },
    get ui_isTritonValid() {
      let res = true;
      if (!self.connection.isValid) res = false;
      if (!self.location.value) res = false;
      if (!self.privateNetworkId.isValid) res = false;
      if (!self.imageId.isValid) res = false;
      return res;
    },
    get dryRun_tritonPathList() {
      return [
        //
        "spec.provider.triton.region",
        "spec.provider.triton.connection",
        "spec.provider.triton.connection.url",
        "spec.provider.triton.connection.account",
        "spec.provider.triton.connection.user",
        "spec.provider.triton.connection.privateKeySecretLink",
        "spec.provider.triton.privateNetworkId",
        "spec.provider.triton.imageId",
        "spec.provider.triton.sshKeys",
        ...self.sshKeys.items.map((_, index) => `spec.provider.triton.sshKeys[${index}]`),
      ];
    },
    get ui_isAdvancedValid() {
      let res = true;
      if (!self.preInstallScript.isValid) res = false;
      if (!self.autoscaler.isValid) res = false;
      if (!self.networking.isValid) res = false;
      if (!self.loadBalancer.isValid) res = false;
      return res;
    },
    get dryRun_advancedPathList() {
      return [
        //
        "spec.provider.triton.preInstallScript",
        ...self.autoscaler.dryRun_pathList("triton"),
        ...self.networking.dryRun_pathList("triton"),
        "spec.provider.triton.loadBalancer",
        "spec.provider.triton.loadBalancer.gateway",
        "spec.provider.triton.loadBalancer.manual",
        "spec.provider.triton.loadBalancer.manual.packageId",
        "spec.provider.triton.loadBalancer.manual.imageId",
        "spec.provider.triton.loadBalancer.manual.publicNetworkId",
        "spec.provider.triton.loadBalancer.manual.privateNetworkIds",
        "spec.provider.triton.loadBalancer.manual.metadata",
        "spec.provider.triton.loadBalancer.manual.tags",
        "spec.provider.triton.loadBalancer.manual.count",
        "spec.provider.triton.loadBalancer.manual.cnsInternalDomain",
        "spec.provider.triton.loadBalancer.manual.cnsPublicDomain",
      ];
    },
    get asObject() {
      let obj: any = {
        connection: self.connection.asObject,
        location: self.location.value,
        privateNetworkId: self.privateNetworkId.value,
        firewallEnabled: self.firewallEnabled,
        imageId: self.imageId.value,
        sshKeys: self.sshKeys.items.map((i) => i.firstValue),
        autoscaler: self.autoscaler.asObject,
        networking: self.networking.asObject,
        preInstallScript: self.preInstallScript.value,
        nodePools: self.nodePools.map((n) => n.asObject),
        loadBalancer: self.loadBalancer.asObject,
      };
      return obj;
    },
  }));
export interface Mk8sDraftProviderTritonMobx extends Instance<typeof Mk8sDraftProviderTritonModel> {}
