import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGLabel } from "../../../../newcomponents/text/label";
import { IS_DEPLOYMENT_ENV_PROD } from "../../../../envVariables";
import { NGSwitch } from "../../../../newcomponents/switch";
import { ExternalLink } from "react-feather";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import NGAlert from "../../../../newcomponents/alert";
import { BasePathContext } from "../../../../reactContexts/basePathContext";
import { Link } from "react-router-dom";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateAddonsRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const basePath = React.useContext(BasePathContext);

  // Update when new addon is added, below list is the list of addons which doesn't have their own config page.
  const anyAddonsDryRun = mk8sDryRun.response?.details.issues.filter((i) =>
    [
      "spec.addOns", //
      "spec.addOns.dashboard",
      "spec.addOns.localPathStorage",
      "spec.addOns.sysbox",
    ].includes(i.path),
  );

  const renderConfigureButton = (isEnabled: boolean, path: string) => {
    return isEnabled ? (
      <Link to={`${basePath}/-addons/-${path}`} className="color-link">
        Configure
      </Link>
    ) : null;
  };

  return (
    <>
      {anyAddonsDryRun?.length > 0 ? (
        <>
          <div className="flex flex-col gap-2 mb-6">
            {anyAddonsDryRun.map((dr) => (
              <NGAlert type={dr.severity === "error" ? "error" : "warning"} message={dr.message} />
            ))}
          </div>
        </>
      ) : null}
      <NGLabel>Add Ons</NGLabel>
      <div className={`flex flex-wrap gap-4`}>
        {IS_DEPLOYMENT_ENV_PROD ? null : (
          <>
            <div className={`border p-4 rounded`} style={{ width: 300 }}>
              <div className="flex items-center gap-2">
                <NGLabel>Internal Developer Platform</NGLabel>
                <NGSwitch
                  aria-label="Internal Developer Platform"
                  isDisabled={IS_DEPLOYMENT_ENV_PROD}
                  value={mk8sDraft.addon_idp_enabled}
                  onChange={mk8sDraft.set_addon_idp_enabled}
                />
              </div>
              <div className="text-sm mt-4">Deploy Control Plane's Internal developer platform</div>
            </div>
          </>
        )}
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Dashboard</NGLabel>
            <NGSwitch
              aria-label="Dashboard"
              value={mk8sDraft.addon_dashboard.isEnabled}
              onChange={(checked) => mk8sDraft.addon_dashboard.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm mt-4">Dashboard addon enables the Kubernetes Dashboard UI for this cluster.</div>
        </div>

        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>AWS ECR</NGLabel>
            <NGSwitch
              aria-label="AWS ECR"
              value={mk8sDraft.addon_awsEcr.isEnabled}
              onChange={(checked) => mk8sDraft.addon_awsEcr.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm my-4">Access to private Docker container images in AWS ECR.</div>
          {renderConfigureButton(mk8sDraft.addon_awsEcr.isEnabled, "aws-ecr")}
        </div>
        {mk8sDraft.provider.value === "aws" ? (
          <>
            <div className={`border p-4 rounded`} style={{ width: 300 }}>
              <div className="flex items-center gap-2">
                <NGLabel>AWS EFS</NGLabel>
                <NGSwitch
                  aria-label="AWS EFS"
                  value={mk8sDraft.addon_awsEfs.isEnabled}
                  onChange={(checked) => mk8sDraft.addon_awsEfs.setIsEnabled(checked)}
                />
              </div>
              <div className="text-sm my-4">Use AWS Elastic File System</div>
              {renderConfigureButton(mk8sDraft.addon_awsEfs.isEnabled, "aws-efs")}
            </div>
            <div className={`border p-4 rounded`} style={{ width: 300 }}>
              <div className="flex items-center gap-2">
                <NGLabel>AWS ELB</NGLabel>
                <NGSwitch
                  aria-label="AWS ELB"
                  value={mk8sDraft.addon_awsElb.isEnabled}
                  onChange={(checked) => mk8sDraft.addon_awsElb.setIsEnabled(checked)}
                />
              </div>
              <div className="text-sm my-4">Add support for Network Load Balancer (NLB).</div>
              {renderConfigureButton(mk8sDraft.addon_awsElb.isEnabled, "aws-elb")}
            </div>
          </>
        ) : null}
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>AWS Workload Identity</NGLabel>
            <NGSwitch
              aria-label="AWS Workload Identity"
              value={mk8sDraft.addon_awsWorkloadIdentity.isEnabled}
              onChange={(checked) => mk8sDraft.addon_awsWorkloadIdentity.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm mt-4">Allow your pods to assume AWS IAM Roles</div>
        </div>
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Azure Container Registry</NGLabel>
            <NGSwitch
              aria-label="Azure Container Registry"
              value={mk8sDraft.addon_azureAcr.isEnabled}
              onChange={(checked) => mk8sDraft.addon_azureAcr.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm my-4">Access to private Docker container images in ACR</div>
          {renderConfigureButton(mk8sDraft.addon_azureAcr.isEnabled, "azure-acr")}
        </div>
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Azure Workload Identity</NGLabel>
            <NGSwitch
              aria-label="Azure Workload Identity"
              value={mk8sDraft.addon_azureWorkloadIdentity.isEnabled}
              onChange={(checked) => mk8sDraft.addon_azureWorkloadIdentity.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm my-4">Allow your pods to assume Azure Identities</div>
          {renderConfigureButton(mk8sDraft.addon_azureWorkloadIdentity.isEnabled, "azure-workload-identity")}
        </div>
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Local Path Storage</NGLabel>
            <NGSwitch
              aria-label="Local Path Storage"
              value={mk8sDraft.addon_localPathStorage.isEnabled}
              onChange={(checked) => mk8sDraft.addon_localPathStorage.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm mt-4">Create PVCs backed by local volumes</div>
        </div>
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Logs</NGLabel>
            <NGSwitch
              aria-label="Logs"
              value={mk8sDraft.addon_logs.isEnabled}
              onChange={(checked) => mk8sDraft.addon_logs.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm my-4">Let Control Plane collect and store pod logs for this cluster</div>
          {renderConfigureButton(mk8sDraft.addon_logs.isEnabled, "logs")}
        </div>
        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Metrics</NGLabel>
            <NGSwitch
              aria-label="Metrics"
              value={mk8sDraft.addon_metrics.isEnabled}
              onChange={(checked) => mk8sDraft.addon_metrics.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm my-4">Let Control Plane collect and store Prometheus metrics for this cluster</div>
          {renderConfigureButton(mk8sDraft.addon_metrics.isEnabled, "metrics")}
        </div>
        {IS_DEPLOYMENT_ENV_PROD ? null : (
          <div className={`border p-4 rounded`} style={{ width: 300 }}>
            <div className="flex items-center gap-2">
              <NGLabel>NVIDIA</NGLabel>
              <NGSwitch
                aria-label="NVIDIA"
                value={mk8sDraft.addon_nvidia.isEnabled}
                onChange={(checked) => mk8sDraft.addon_nvidia.setIsEnabled(checked)}
              />
            </div>
            <div className="text-sm my-4">Install all required components to run GPU workloads on this cluster</div>
            {renderConfigureButton(mk8sDraft.addon_nvidia.isEnabled, "nvidia")}
          </div>
        )}

        <div className={`border p-4 rounded`} style={{ width: 300 }}>
          <div className="flex items-center gap-2">
            <NGLabel>Sysbox</NGLabel>
            <NGSwitch
              aria-label="Sysbox"
              value={mk8sDraft.addon_sysbox.isEnabled}
              onChange={(checked) => mk8sDraft.addon_sysbox.setIsEnabled(checked)}
            />
          </div>
          <div className="text-sm mt-4">
            <span>Use cri-o container runtime with better isolation.</span>

            <a className="ml-1 color-link" href={`https://github.com/nestybox/sysbox`} target={"_blank"}>
              Learn more
              <ExternalLink className="ml-1 inline-block feather-icon" style={{ transform: "translateY(2px)" }} />
            </a>
          </div>
        </div>
      </div>
      {/* TODO */}
      {/* <div className="mt-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={`spec.addOns.awsEFS`}
          paths={[
            `spec.addOns.azureWorkloadIdentity`,
            `spec.addOns.azureWorkloadIdentity.tenantId`,
            `spec.addOns.awsWorkloadIdentity`,
            `spec.addOns.localPathStorage`,
            `spec.addOns.metrics`,
            `spec.addOns.metrics.coreDns`,
            `spec.addOns.metrics.kubelet`,
            `spec.addOns.metrics.kubeState`,
            `spec.addOns.metrics.nodeExporter`,
            `spec.addOns.metrics.cadvisor`,
            `spec.addOns.metrics.scrapeAnnotated`,
            `spec.addOns.metrics.scrapeAnnotated.intervalSeconds`,
            `spec.addOns.metrics.scrapeAnnotated.includeNamespaces`,
            `spec.addOns.metrics.scrapeAnnotated.excludeNamespaces`,
            `spec.addOns.metrics.scrapeAnnotated.retainLabels`,
            `spec.addOns.logs`,
            `spec.addOns.logs.auditEnabled`,
            `spec.addOns.logs.includeNamespaces`,
            `spec.addOns.logs.excludeNamespaces`,
            `spec.addOns.nvidia`,
            `spec.addOns.nvidia.taintGPUNodes`,
            `spec.addOns.awsEFS`,
            `spec.addOns.awsEFS.roleArn`,
          ]}
        />
      </div> */}
    </>
  );
};

export const Mk8sCreateAddons = observer(Mk8sCreateAddonsRaw);
