import * as React from "react";
import { observer } from "mobx-react-lite";
import { humanizer as humanizerFactory, HumanizerOptions } from "humanize-duration";
import { IS_DEPLOYMENT_ENV_TEST } from "../envVariables";
import { Tooltip as AntTooltip, notification } from "antd";
import moment from "moment-timezone";
import { Timezone } from "../mobxStores/userData/timezone";
import { NGButton } from "../newcomponents/button/Button";
import clsx from "clsx";

const humanizeUnits = ["y", "mo", "w", "d", "h", "m"];
const conversionRates: Record<string, number> = {
  ms: 1,
  s: 1000,
  m: 1000 * 60,
  h: 1000 * 60 * 60,
  d: 1000 * 60 * 60 * 24,
  w: 1000 * 60 * 60 * 24 * 7,
  mo: 1000 * 60 * 60 * 24 * 30,
  y: 1000 * 60 * 60 * 24 * 365,
};

export function humanize(ms: number, ago?: boolean, options?: HumanizerOptions) {
// export function humanizeDuration(ms: number, options?: HumanizerOptions): string {
  ms = new Date().getTime() - ms;
  // Merge provided options with default options
  const finalOptions: HumanizerOptions = {
    round: false,
    units: humanizeUnits as any,
    largest: 1,
    ...(options || {}),
  };

  // Floor the duration to the smallest unit
  const flooredDuration: number = floorDuration(ms, finalOptions.units || humanizeUnits);

  // Note: If the duration is approximately 2 months, it will return "1 month" as an output
  const result = humanizerFactory(finalOptions)(flooredDuration, finalOptions);
  if (result === "0 minutes") {
    return "Now";
  }

  if (ago) {
    return result + " ago";
  }
  return result;
}

function floorDuration(milliseconds: number, units: any[]): number {
  // Get the smallest unit from the unit array
  const smallestUnit: string = units[units.length - 1];

  // Get the conversion rate for the smallest unit
  const conversionRate: number = conversionRates[smallestUnit];

  // Floor the duration to the nearest conversion rate
  return Math.floor(milliseconds / conversionRate) * conversionRate;
}

interface Props {
  iso: string;
  prefix?: string;
  format?: string;
  utc?: boolean;
  invalidText?: string;
  style?: React.CSSProperties;
  className?: string;
}
const DateStringRaw: React.FC<Props> = ({
  iso,
  prefix,
  format,
  utc = false,
  invalidText = "",
  style = {},
  className = "",
}) => {
  // TODO centralize this
  function getDebug() {
    return (localStorage.getItem("CPLN_DEBUG") || "") === "true";
  }
  const [_debug] = React.useState(getDebug());
  const debug = IS_DEPLOYMENT_ENV_TEST || _debug;

  function getM() {
    return moment.utc(iso);
  }
  const [m, setM] = React.useState(getM());
  React.useEffect(() => {
    setM(getM());
  }, [iso]);

  function getMLocal() {
    return getM().clone().tz(Timezone.value);
  }
  const [mLocal, setMLocal] = React.useState(getMLocal);
  React.useEffect(() => {
    setMLocal(getMLocal());
  }, [iso, m, Timezone.value]);

  const debugInfo = [
    { label: "Timezone", value: Timezone.value },
    { label: "ISO", value: iso },
    { label: "Current TZ default format", value: mLocal.format() },
    { label: "In UTC default format", value: m.format() },
    { label: "Current TZ format", value: mLocal.format(format), skip: !format || format.includes("humanize") },
    { label: "In UTC format", value: m.format(format), skip: !format || format.includes("humanize") },
    { label: "Humanized", value: humanize(m.valueOf(), false) },
  ];

  if (!iso) {
    return null;
  }

  if (!m.isValid()) {
    return <span>{invalidText}</span>;
  }

  return (
    <AntTooltip
      mouseEnterDelay={1}
      trigger={["click", "hover"]}
      title={
        <div className="text-xs flex flex-col gap-1">
          {debugInfo.map((debugInfo, index) => {
            if (debugInfo.skip) {
              return null;
            }
            return (
              <div key={index}>
                <div>{debugInfo.label}</div>
                <div>{debugInfo.value}</div>
              </div>
            );
          })}
          <NGButton
            variant="secondary"
            size={"small"}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(debugInfo));
              notification.info({ message: "Copied to clipboard" });
            }}
          >
            Copy
          </NGButton>
        </div>
      }
      open={debug === false ? false : undefined}
    >
      <span className={clsx(className)} style={style}>
        {prefix ? `${prefix} ` : ""}
        {format?.includes("humanize")
          ? humanize(m.valueOf(), format.includes("Ago"))
          : utc
          ? m.format(format)
          : mLocal.format(format)}
      </span>
    </AntTooltip>
  );
};

export const DateString = observer(DateStringRaw);
