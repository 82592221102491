import { observable, makeObservable, flow, action, computed } from "mobx";
import { request } from "../../services/cpln";
import { ParsedLocation } from "../parsedLocation/parsedLocation";
import { UIData } from "../uiData/uiData";
import { UserData } from "../userData/userData";
import { CustomLogoContext } from "./customLogoContext";

class ConsoleContextMobx {
  orgTimeoutSeconds: number = 15 * 60;
  org: string | null = null;
  gvc: string | null = null;
  accountId: string | null = null;
  linkedAccount: string | null = null;
  isChangingContext: boolean = false;

  redirect: string | null = null;

  constructor() {
    makeObservable(this, {
      orgTimeoutSeconds: observable,
      org: observable,
      gvc: observable,
      linkedAccount: observable,
      isChangingContext: observable,
      redirect: observable,
      setInitialContext: flow,
      updateOrgTimeoutSeconds: flow,
      setOrg: flow,
      setGVC: action,
      setRedirect: action,
      setIsChangingContext: action,
      setAccountId: action,
      clearRedirect: action,
      clearOrg: action,
      clearGVC: action,
      hasOrg: computed,
      hasGVC: computed,
    });
  }

  clearRedirect() {
    this.redirect = null;
  }

  setIsChangingContext(value: boolean) {
    this.isChangingContext = value;
  }

  setRedirect(value: string) {
    this.redirect = value;
  }

  *setInitialContext() {
    const initialDashboard = ParsedLocation.parseDashboardResult(UIData.initialUrl);
    let gvcFromUrl = "";
    if (initialDashboard === "console") {
      // Get from initial url
      const initialUrl = UIData.initialUrl;
      let orgFromUrl = "";
      try {
        orgFromUrl = initialUrl.split("/console/org/")[1].split("/")[0];
      } catch (e) {}
      if (orgFromUrl.includes("-select")) {
        orgFromUrl = "";
      }
      try {
        if (orgFromUrl) {
          gvcFromUrl = initialUrl.split(`/console/org/${orgFromUrl}/gvc/`)[1].split("/")[0];
        }
      } catch (e) {}
      if (orgFromUrl) {
        if (UserData.orgNames.includes(orgFromUrl)) {
          this.org = orgFromUrl;
          yield UserData.requestGVCNames();
        } else {
          this.org = null;
          yield UserData.requestGVCNames();
          window.history.replaceState(null, "", `/console/org/${orgFromUrl}/notfound`);
          return;
        }
      }
    }

    if (!this.org) {
      const localTabOrg = window.localStorage.getItem(`${UIData.tabId}-org`);
      const localOrg = window.localStorage.getItem(`org`);
      let orgToSet = localTabOrg || localOrg;
      if (orgToSet && UserData.orgNames.includes(orgToSet)) {
        this.org = orgToSet;
        yield UserData.requestGVCNames();
      } else {
        if (UserData.orgNames.length === 1) {
          this.org = UserData.orgNames[0];
          yield UserData.requestGVCNames();
        } else {
          this.org = null;
          yield UserData.requestGVCNames();
        }
      }
    }

    if (this.org) {
      const localTabGvc = window.localStorage.getItem(`${UIData.tabId}-${this.org}`);
      const localGvc = window.localStorage.getItem(`${this.org}`);
      let gvcsToSet = [gvcFromUrl, localTabGvc, localGvc];
      for (let gvcToSet of gvcsToSet) {
        if (gvcToSet && UserData.gvcNames.includes(gvcToSet)) {
          this.gvc = gvcToSet;
          break;
        }
      }

      if (!this.gvc) {
        if (UserData.gvcNames.length === 1) {
          this.gvc = UserData.gvcNames[0];
        } else {
          this.gvc = null;
        }
      }
    }

    if (this.org) {
      window.localStorage.setItem(`${UIData.tabId}-org`, this.org);
      window.localStorage.setItem(`org`, this.org);
      if (this.gvc) {
        window.localStorage.setItem(`${UIData.tabId}-${this.org}`, this.gvc);
        window.localStorage.setItem(this.org, this.gvc);
      }
      yield this.updateOrgTimeoutSeconds();
    }

    if (this.gvc) {
      UserData.requestHasWorkload();
    }

    if (this.org) {
      yield CustomLogoContext.checkCustomLogo(this.org);
    }
  }

  *updateOrgTimeoutSeconds() {
    try {
      const { data: orgItem } = yield request({ url: `/org/${this.org}` });
      this.orgTimeoutSeconds = orgItem.spec?.sessionTimeoutSeconds || 15 * 60;
    } catch (e) {
      console.error("Failed to get org item and its timeoutSeconds, will use the default 15 mins");
      this.orgTimeoutSeconds = 15 * 60;
    }
  }

  // Called from org change dropdown only
  *setOrg(newOrg: string) {
    this.org = newOrg;
    this.gvc = null;
    // Persist org
    window.localStorage.setItem(`${UIData.tabId}-org`, this.org);
    window.localStorage.setItem(`org`, this.org);
    // Fetch gvc names of new org
    yield UserData.requestGVCNames();
    // Load local gvc and select it if possible
    const localTabGVC = window.localStorage.getItem(`${UIData.tabId}-${newOrg}`);
    const localGVC = window.localStorage.getItem(newOrg);
    if (localTabGVC) {
      this.gvc = localTabGVC;
    } else if (localGVC) {
      this.gvc = localGVC;
    } else if (UserData.gvcNames.length === 1) {
      this.gvc = UserData.gvcNames[0];
    }
    yield this.updateOrgTimeoutSeconds();
    yield CustomLogoContext.checkCustomLogo(newOrg);
  }

  // Called from gvc change dropdown only
  setGVC(newGVC: string) {
    this.gvc = newGVC;
    // Persist gvc
    window.localStorage.setItem(`${UIData.tabId}-${this.org}`, this.gvc);
    window.localStorage.setItem(this.org!, this.gvc);
    UserData.requestHasWorkload();
  }

  setLinkedAccount(linkedAccount: string | null) {
    this.linkedAccount = linkedAccount;
  }

  setAccountId(accountId: string | null) {
    this.accountId = accountId;
  }

  clearOrg() {
    this.orgTimeoutSeconds = 15 * 60;
    this.org = null;
    UserData.requestGVCNames();
    this.clearGVC();
  }

  clearGVC() {
    this.gvc = null;
    UserData.requestHasWorkload();
  }

  get hasOrg() {
    return this.org !== null;
  }

  get hasGVC() {
    return this.gvc !== null;
  }
}

export const ConsoleContext = new ConsoleContextMobx();
