import * as React from "react";
import { App } from "./app";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Spin } from "antd";
import * as Sentry from "@sentry/react";
import { Discovery } from "./mobxStores/discovery/discovery";
import { User } from "./mobxStores/user/user";
import {
  DEPLOYMENT_ENV,
  HUBSPOT_SERVICE_URL,
  IS_DEPLOYMENT_ENV_TEST,
  IS_HUBSPOT_INTEGRATION_ENABLED,
  IS_SENTRY_ENABLED,
  STORAGE_KEY_USER_ACTIVE_TIMESTAMP,
} from "./envVariables";
import { request } from "./services/cpln";
import { Theme } from "./mobxStores/uiData/theme";
import { SignIn } from "./components/system/auth/signin";
import { SidebarContext } from "./mobxStores/uiData/sidebarContext";
import { Trial } from "./components/system/auth/trial/trial";
import { TrialSignIn } from "./components/system/auth/trial/trial-signin/trial-signin";
import { BillingContext } from "./mobxStores/billingContext/billingContext";
import { SignUpWithId } from "./components/system/auth/signin/signUpWithId";
import { DebugContext } from "./mobxStores/debug";
import { MediaQueryContext, useInitMediaQueryContext } from "./components/detail/mediaQueryContext";

const EntryPointRaw: React.FC = () => {
  const mediaQueryContext = useInitMediaQueryContext();

  React.useEffect(() => {
    Theme.setToInitialTheme();
    SidebarContext.Initalize();
    DebugContext.initalize();
  }, []);

  React.useEffect(() => {
    if (!Discovery.requested || Discovery.error) {
      return;
    }

    // Discovery is valid at this point

    // Check if request is coming from admin panel
    if (window.location.hash.includes("#type=")) {
      const data = Object.fromEntries(
        window.location.hash
          .slice(1)
          .split("&")
          .map((x) => x.split("=")),
      );
      if (data.type === "org") {
        User.inspect(data);
      } else if (data.type === "user") {
        User.impersonate(data);
      }
    } else {
      User.initializeFirebase();
    }
  }, [Discovery.requested, Discovery.error]);

  React.useEffect(() => {
    function setActiveTimestamp() {
      localStorage.setItem(STORAGE_KEY_USER_ACTIVE_TIMESTAMP, String(new Date().getTime()));
    }

    let setActiveTimestampIntervalId: any = null;

    if (User.type !== "none") {
      BillingContext.fetchChargableItems();
    }

    if (User.type !== "user") {
      Sentry.setUser(null);
      return;
    }

    if (User.type === "user") {
      const SET_ACTIVE_TIMESTAMP_INTERVAL_MS = 10 * 1000;
      setActiveTimestamp();
      setActiveTimestampIntervalId = setInterval(setActiveTimestamp, SET_ACTIVE_TIMESTAMP_INTERVAL_MS);

      if (IS_HUBSPOT_INTEGRATION_ENABLED && !User.isCplnTeam) {
        const time = new Date().toISOString().slice(0, 10);
        request({
          service: "self",
          method: "post",
          url: `${HUBSPOT_SERVICE_URL}/lastlogin`,
          body: { time, environment: DEPLOYMENT_ENV },
        }).catch(() => {
          console.error("Failure on handling crmId lastLogin");
        });
      }

      if (IS_SENTRY_ENABLED) {
        Sentry.setUser({ username: User.name, email: User.email });
      }
    }

    return () => {
      if (setActiveTimestampIntervalId) {
        clearInterval(setActiveTimestampIntervalId);
      }
    };
  }, [User.type]);

  if (Discovery.requesting) {
    return (
      <MediaQueryContext.Provider value={mediaQueryContext}>
        <div
          className={`h-screen w-screen flex flex-col items-center justify-center text-normal`}
          style={{ backgroundColor: "var(--color-drop)" }}
        >
          <div className="z-10 h-auto w-40">
            <img
              className="px-4 py-3 object-contain"
              src={`/resources/logos/controlPlaneLogo${Theme.theme === "dark" ? "White" : ""}.svg`}
              alt={"Control Plane Logo"}
            />
          </div>
          <Spin />
          {IS_DEPLOYMENT_ENV_TEST ? <div className="mt-4 text-lg">Requesting Discovery Endpoint</div> : null}
        </div>
      </MediaQueryContext.Provider>
    );
  }

  if (Discovery.error) {
    return (
      <MediaQueryContext.Provider value={mediaQueryContext}>
        <div
          className={`h-screen w-screen flex flex-col items-center justify-center text-normal`}
          style={{ backgroundColor: "var(--color-drop)" }}
        >
          <div className="z-10 h-auto w-40">
            <img
              className="px-4 py-3 object-contain"
              src={`/resources/logos/controlPlaneLogo${Theme.theme === "dark" ? "White" : ""}.svg`}
              alt={"Control Plane Logo"}
            />
          </div>
          <div className="px-4 py-2">Failed to connect to server. Please try again.</div>
          {IS_DEPLOYMENT_ENV_TEST ? <div className="mt-4 text-lg">{Discovery.error}</div> : null}
        </div>
      </MediaQueryContext.Provider>
    );
  }

  if (!User.firstCheckDone) {
    return (
      <MediaQueryContext.Provider value={mediaQueryContext}>
        <div
          className={`h-screen w-screen flex flex-col items-center justify-center text-normal`}
          style={{ backgroundColor: "var(--color-drop)" }}
        >
          <div className="z-10 h-auto w-40">
            <img
              className="px-4 py-3 object-contain"
              src={`/resources/logos/controlPlaneLogo${Theme.theme === "dark" ? "White" : ""}.svg`}
              alt={"Control Plane Logo"}
            />
          </div>
          <Spin />
          {IS_DEPLOYMENT_ENV_TEST ? <div className="mt-4 text-lg">Making First Login Check</div> : null}
        </div>
      </MediaQueryContext.Provider>
    );
  }

  if (!User.isLoggedIn) {
    const noUserRouter = createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route path={"/trial/signin"} element={<TrialSignIn key={"trialSignin"} />} />
          <Route path={"/trial"} element={<Trial key={"trial"} />} />
          <Route path={"/signup/id/:id"} element={<SignUpWithId key={"signupWithId"} />} />
          <Route path={"/signup"} element={<SignIn key={"signup"} />} />
          <Route path={"*"} element={<SignIn key={"signin"} />} />
        </>,
      ),
    );

    return (
      <MediaQueryContext.Provider value={mediaQueryContext}>
        <RouterProvider router={noUserRouter} />
      </MediaQueryContext.Provider>
    );
  }

  if (User.isProcessing) {
    return (
      <MediaQueryContext.Provider value={mediaQueryContext}>
        <div
          className={`h-screen w-screen flex flex-col items-center justify-center text-normal`}
          style={{ backgroundColor: "var(--color-drop)" }}
        >
          <div className="z-10 h-auto w-40">
            <img
              className="px-4 py-3 object-contain"
              src={`/resources/logos/controlPlaneLogo${Theme.theme === "dark" ? "White" : ""}.svg`}
              alt={"Control Plane Logo"}
            />
          </div>
          <Spin />
          {IS_DEPLOYMENT_ENV_TEST ? <div className="mt-4 text-lg">Logged In. Requesting initial user data.</div> : null}
        </div>
      </MediaQueryContext.Provider>
    );
  }

  return (
    <MediaQueryContext.Provider value={mediaQueryContext}>
      <App />
    </MediaQueryContext.Provider>
  );
};

export const EntryPoint = observer(EntryPointRaw);
