import * as React from "react";
import { observer } from "mobx-react-lite";
import { StringMobx } from "../../../mobxDataModels/stringModel";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import NGAlert from "../../../newcomponents/alert";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGLabel } from "../../../newcomponents/text/label";

interface Props {
  isDedicatedLoadBalancerEnabled: boolean;
  setIsDedicatedLoadBalancerEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  trustedProxies: StringMobx;
  status5xx: StringMobx;
  status401: StringMobx;
}
const GVCCreateLoadBalancerRaw: React.FC<Props> = ({
  isDedicatedLoadBalancerEnabled,
  setIsDedicatedLoadBalancerEnabled,
  trustedProxies,
  status5xx,
  status401,
}) => {
  return (
    <>
      <div className="mb-4">
        <div className="mb-2">
          <NGSwitch value={isDedicatedLoadBalancerEnabled} onChange={setIsDedicatedLoadBalancerEnabled}>
            <NGLabelText>Dedicated Load Balancer</NGLabelText>
          </NGSwitch>
        </div>
        <NGAlert
          style={{ maxWidth: 600 }}
          type="info"
          message={`Creates a dedicated load balancer in each location and enables additional Domain features: custom ports,
            protocols and wildcard hostnames. Charges apply for each location.`}
        />
      </div>
      {isDedicatedLoadBalancerEnabled ? (
        <>
          <NGFormElement
            name={"trustedProxies"}
            label={trustedProxies.label}
            value={trustedProxies.value}
            onChange={trustedProxies.setValue}
            required={trustedProxies.isRequired}
            error={trustedProxies.error}
            info={[
              "Controls the address used for request logging and for setting the X-Envoy-External-Address header.",
              "If set to 1, then the last address in an existing X-Forwarded-For header will be used in place of the source client IP address.",
              "If set to 2, then the second to last address in an existing X-Forwarded-For header will be used in place of the source client IP address. If the XFF header does not have at least two addresses or does not exist then the source client IP address will be used instead.",
              "Default value is 0.",
            ]}
          />
          <NGLabel size={3} className="mb-2 mt-6">
            Redirects
          </NGLabel>
          <NGFormElement
            name={"status5xx"}
            label={status5xx.label}
            value={status5xx.value}
            onChange={status5xx.setValue}
            required={status5xx.isRequired}
            error={status5xx.error}
            info={["Specify the redirect url for any 500 level status code."]}
          />
          <NGFormElement
            name={"status401"}
            label={status401.label}
            value={status401.value}
            onChange={status401.setValue}
            required={status401.isRequired}
            error={status401.error}
            info={[
              `An optional url redirect for 401 responses.`,
              `Supports envoy format strings to include request information.`,
              `E.g. https://your-oauth-server/oauth2/authorize?return_to=%REQ(:path)%&client_id=your-client-id`,
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export const GVCCreateLoadBalancer = observer(GVCCreateLoadBalancerRaw);
