import * as React from "react";
import { MarketplaceReleaseInstance, ReleaseState } from "../types/release";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { useNavigate } from "react-router-dom";
import { Template } from "../types/template";
import { CPLN_HELM_RELEASE_PREFIX, request, resourceLink } from "../../../services/cpln";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { Modal, notification } from "antd";
import jsYaml from "js-yaml";
import NGAlert from "../../../newcomponents/alert";
import { Loader } from "../../../components/layout/loader";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import { NGLabel } from "../../../newcomponents/text/label";
import { CodeEditor } from "../../group/identityMatcher/codeEditor";
import { NGButton } from "../../../newcomponents/button/Button";
import { Copy, Download } from "react-feather";
import { k8sKeySort } from "../../../services/utils";
import { useDetailContext } from "../../../components/detail/detailContext";
import { getTemplateVersion, getVersions } from "../utils";
import { DOCS_URL, MARKETPLACE_SERVICE_URL } from "../../../envVariables";
import { FilesReducerActionType, useFilesReducer } from "../template/filesReducer";
import { CodeEditorModal } from "../../../components/modals/codeEditorModal";
import { isEqual } from "lodash";
import { CodeSnippet } from "../../../components/generic/codeSnippet/codeSnippet";

interface Props {
  release: MarketplaceReleaseInstance;
}
export const ReleaseUpgrade: React.FC<Props> = ({ release }) => {
  const formDataRef = React.useRef(new NGFormData());

  const { fetchItem } = useDetailContext();

  const basePath = React.useContext(BasePathContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [template, setTemplate] = React.useState<Template>(null as any);
  const [isLoadingTemplate, setIsLoadingTemplate] = React.useState(false);
  const [isLoadingUpgrade, setIsLoadingUpgrade] = React.useState(false);

  const [state, setState] = React.useState<ReleaseState>(null as any);
  const versions = getVersions(template);
  const templateVersion = getTemplateVersion(template, release.version);

  const [defaultValues, setDefaultValues] = React.useState("");
  const [initialValues, setInitialValues] = React.useState("");
  const [values, setValues] = React.useState("");

  const [isTemplateView, setIsTemplateView] = React.useState(false);
  const [helmTemplates, setHelmTemplates] = React.useState<any[]>([]);

  const [filesState, filesDispatch] = useFilesReducer();
  const [codeEditorOpenProperty, setCodeEditorOpenProperty] = React.useState("");

  React.useEffect(() => {
    fetchTemplate();
    fetchState();
  }, []);

  async function fetchTemplate() {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await request({ service: "marketplace", url: `/template/${release.template}` });
      setTemplate(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      setError(errorMessage);
    }
  }

  async function fetchState() {
    try {
      const { data } = await request({
        url: resourceLink("secret", CPLN_HELM_RELEASE_PREFIX + release.name) + "/-reveal",
      });
      setState(JSON.parse(data.data.payload));
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e.response?.data?.error;
      if (!errorMessage) errorMessage = e.message;
    }
  }

  React.useEffect(() => {
    if (!state) {
      return;
    }
    if (!template) {
      return;
    }
    if (!versions.includes(release.version)) {
      return;
    }

    const fileDefaults: { [_: string]: string } = {};
    for (const [property, value] of Object.entries(template.versions[release.version].files || {})) {
      fileDefaults[property] = value.content;
    }

    filesDispatch({
      type: FilesReducerActionType.SET_DEFAULTS,
      payload: { content: "", property: "", defaults: fileDefaults },
    });

    const values = template.versions[release.version].values;
    setDefaultValues(values);
  }, [template, state]);

  React.useEffect(() => {
    if (!defaultValues) {
      return;
    }

    const fileDefaults: { [_: string]: string } = {};
    for (const [property, value] of Object.entries(template.versions[release.version].files || {})) {
      fileDefaults[property] = value.content;
    }

    const rev = state.deployments.toSorted((a, b) => {
      if (a.revision > b.revision) return -1;
      if (b.revision > a.revision) return 1;
      return 0;
    })[0];

    const valuesObj: any = jsYaml.load(rev.valuesFiles[0]);
    const valuesObjWithoutFiles: any = jsYaml.load(rev.valuesFiles[0]);

    const fileInitials: { [_: string]: string } = {};

    for (const [property] of Object.entries(template.versions[release.version].files || {})) {
      const fileValueObj = valuesObj[property];
      const fileValueString = jsYaml.dump(fileValueObj);

      let content = fileValueString || fileDefaults[property] || "";

      if (content.trimStart().startsWith("|-")) {
        // remove initial "|- "" and remove indent that was added manually
        content = content
          .slice(3)
          .split("\n")
          .map((l) => l.slice(2))
          .join("\n");
      }

      fileInitials[property] = content;

      filesDispatch({
        type: FilesReducerActionType.SET_FILE,
        payload: { content: content, property: property },
      });
      delete valuesObjWithoutFiles[property];
    }

    filesDispatch({
      type: FilesReducerActionType.SET_INITIALS,
      payload: { content: "", property: "", defaults: fileInitials },
    });

    const valuesObjWithoutFilesString = jsYaml.dump(valuesObjWithoutFiles);

    setInitialValues(valuesObjWithoutFilesString);
    setValues(valuesObjWithoutFilesString);
  }, [defaultValues]);

  function getProcessedValues(): string {
    let processedValues = values;
    if (!templateVersion) {
      return processedValues;
    }
    if (templateVersion.files && Object.keys(templateVersion.files).length > 0) {
      for (let fileProperty of Object.keys(templateVersion.files)) {
        // TODO for handling multi files
        // const fileInfo = templateVersion.chart.files[fileProperty];
        const fileValue = filesState.current[fileProperty] || "";

        const indentCount = 2;
        const indentedFileValue = fileValue
          .split("\n")
          .map((line) => " ".repeat(indentCount) + line)
          .join("\n");

        processedValues += "\n" + fileProperty + ": |- \n" + indentedFileValue;
      }
    }
    return processedValues;
  }

  async function handleUpgrade() {
    try {
      setIsLoadingUpgrade(true);
      await request({
        service: "marketplace",
        method: "post",
        url: `/helm/install`,
        body: {
          org: release.org,
          gvc: release.gvc,
          name: release.name,
          template: release.template,
          version: release.version,
          values: getProcessedValues(),
        },
      });
      notification.success({ message: "Success", description: "Upgraded template" });
      setIsLoadingUpgrade(false);
      navigate(basePath);
      if (fetchItem) {
        fetchItem();
      }
    } catch (e) {
      setIsLoadingUpgrade(false);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      if (errorMessage.startsWith("Command failed: ")) {
        errorMessage = `[` + errorMessage.split(`[`)[1];
      }
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  async function handleTemplate() {
    try {
      setIsLoadingTemplate(true);
      const { data } = await request({
        service: "marketplace",
        method: "post",
        url: `/helm/template`,
        body: {
          org: ConsoleContext.org,
          gvc: release.gvc,
          name: release.name,
          template: template.name,
          version: release.version,
          values: getProcessedValues(),
        },
      });
      setHelmTemplates(jsYaml.loadAll(data.message));
      setIsTemplateView(true);
      notification.success({ message: "Success", description: "Templated" });
      setIsLoadingTemplate(false);
    } catch (e) {
      setIsLoadingTemplate(false);
      setHelmTemplates([]);
      let errorMessage = e.response?.data?.message;
      if (!errorMessage) {
        errorMessage = e.message;
      }
      if (errorMessage.startsWith("Command failed: ")) {
        errorMessage = `[` + errorMessage.split(`[`)[1];
      }
      notification.warning({ message: "Failed", description: errorMessage });
    }
  }

  if (error) {
    return <NGAlert type={"error"} title={"Failed to fetch template values"} message={error} />;
  }

  if (!template || isLoading) {
    return (
      <div className="relative">
        <Loader
          reason="Fetching template or values"
          style={{
            width: 100,
            height: 100,
            margin: 0,
            left: `calc(50% - 50px)`,
            right: "unset",
            top: 150,
            bottom: "unset",
          }}
        />
      </div>
    );
  }

  const filesAreDirty = !isEqual(filesState.current, filesState.initials);

  return (
    <NGFormContext.Provider value={formDataRef.current}>
      <div style={{ maxWidth: 900 }}>
        <div className="header-inputs">
          <div
            className="flex mb-4 px-4 py-1 rounded w-min"
            style={{ backgroundColor: "white", color: "var(--color-gray-1600)" }}
          >
            <div style={{ width: 50 }} className="flex items-center">
              <img className="object-fit" src={`${MARKETPLACE_SERVICE_URL}/icon/${template.name}`} />
            </div>
            <div className="flex flex-col ml-4">
              <span className="leading-none text-lg font-semibold">{template.name}</span>
              <span className="leading-none text-sm">{templateVersion?.category}</span>
            </div>
          </div>
          <div className="flex items-start">
            <div style={{ width: 289, marginRight: 16 }}>
              <NGFormLabel>GVC</NGFormLabel>
              <div className="mb-4">{release.gvc}</div>
            </div>
            <div style={{ width: 289, marginRight: 16 }}>
              <NGFormLabel>Release Name</NGFormLabel>
              <div className="mb-4">{release.name}</div>
            </div>
            <div style={{ width: 290 }}>
              <NGFormLabel>Template Version</NGFormLabel>
              <div className="mb-4">{release.version}</div>
            </div>
          </div>
        </div>
        <NGLabel>Values</NGLabel>
        <div className="text-sm">
          <CodeEditor language="yaml" options={{}} value={values} setValue={setValues} />
        </div>
        {templateVersion ? (
          Object.keys(templateVersion.files || {}).length < 1 ? null : (
            <>
              {Object.entries(templateVersion.files || {}).map(([fileProperty, fileInfo]) => {
                if (fileInfo.type === "multi") {
                  return null;
                }

                const content = filesState.current[fileProperty];

                return (
                  <div className="my-4">
                    {content ? (
                      <div>
                        <NGLabel>{fileInfo.label}</NGLabel>
                        <NGButton variant={"secondary"} onClick={() => setCodeEditorOpenProperty(fileProperty)}>
                          Edit
                        </NGButton>
                      </div>
                    ) : (
                      <div>
                        <NGLabel>{fileInfo.label}</NGLabel>
                        <NGButton
                          className="mt-2"
                          variant="primary"
                          onClick={() => setCodeEditorOpenProperty(fileProperty)}
                        >
                          Set Data
                        </NGButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )
        ) : null}
        <div className="mt-4 flex items-center">
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            disabled={(!filesAreDirty && values === initialValues) || isLoadingUpgrade || isLoadingTemplate}
            variant={"danger"}
            outlined
            onClick={() => {
              filesDispatch({ type: FilesReducerActionType.RESET_TO_INITIALS, payload: { content: "", property: "" } });
              setValues(initialValues);
            }}
          >
            Reset
          </NGButton>
          <NGButton
            style={{ width: 220, marginRight: 10 }}
            // TODO we can have a way of validating the values
            disabled={isLoadingUpgrade || isLoadingTemplate}
            loading={isLoadingTemplate}
            variant={"primary"}
            onClick={() => handleTemplate()}
          >
            Template
          </NGButton>
          <NGButton
            style={{ width: 220 }}
            disabled={isLoadingUpgrade || isLoadingTemplate}
            loading={isLoadingUpgrade}
            variant={"action"}
            onClick={() => handleUpgrade()}
          >
            Upgrade App
          </NGButton>
        </div>
      </div>
      <div className={`mt-4 p-4 border`}>
        <p>
          Install via terminal using the{" "}
          <a href={`${DOCS_URL}/guides/cpln-helm#install-a-release`} target="_blank" className="color-link">
            Helm Install
          </a>{" "}
          CLI command, after{" "}
          <a href={`${DOCS_URL}/guides/cpln-helm#install-a-release`} target="_blank" className="color-link">
            downloading the template repo
          </a>
          , navigating to the template and updating the <span className="font-bold">values.yaml</span> file.
        </p>
        <CodeSnippet
          code={`cpln helm install ${release.name || "{name}"} --tag cpln/marketplace=true --tag cpln/marketplace-gvc=${
            release.gvc || "{gvc}"
          } --tag cpln/marketplace-template=${release.template} --tag cpln/marketplace-template-version=${
            release.version
          } --org ${ConsoleContext.org} --gvc ${release.gvc || "{gvc}"}`}
          className="mt-3 text-sm"
        />
      </div>
      {!!codeEditorOpenProperty ? (
        <CodeEditorModal
          title={templateVersion ? templateVersion.files![codeEditorOpenProperty].label : "File"}
          initialValue={filesState.current[codeEditorOpenProperty] || ""}
          open={!!codeEditorOpenProperty}
          onOk={(value) => {
            filesDispatch({
              type: FilesReducerActionType.SET_FILE,
              payload: { content: value, property: codeEditorOpenProperty },
            });
            setCodeEditorOpenProperty("");
          }}
          onClose={() => setCodeEditorOpenProperty("")}
        />
      ) : null}
      {isTemplateView ? (
        <Modal
          open={isTemplateView}
          width={"90vw"}
          styles={{ body: { height: "70vh", padding: 0 } }}
          onCancel={() => setIsTemplateView(false)}
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
          footer={
            <div className="modal-actions">
              <NGButton variant="secondary" onClick={() => setIsTemplateView(false)} disabled={isLoadingUpgrade}>
                Close
              </NGButton>
              <NGButton
                variant="primary"
                onClick={() => handleUpgrade()}
                loading={isLoadingUpgrade}
                disabled={isLoadingUpgrade}
              >
                Install
              </NGButton>
            </div>
          }
        >
          <div className="h-full overflow-auto p-1">
            <div className="flex items-center gap-2 mb-4">
              <NGLabel>{helmTemplates.length} Items</NGLabel>
              <div className="flex-grow" />
              <NGButton
                onClick={() => {
                  navigator.clipboard.writeText(jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort }));
                  notification.success({ message: "Copied all to clipboard" });
                }}
                variant="secondary"
                size={"small"}
                renderIcon={(_, props) => <Copy {...props} />}
              >
                Copy
              </NGButton>
              <NGButton
                onClick={() => {
                  notification.success({ message: "Download initiated" });
                  const blob = jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort });
                  const file = new Blob([blob], { type: `text/yaml` });
                  const href = URL.createObjectURL(file);
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.classList.add("cpln-temp-a");
                  a.download = `marketplace-${template.name}-items.yaml`;
                  a.href = href;
                  a.click();
                }}
                variant="secondary"
                size={"small"}
                renderIcon={(_, props) => <Download {...props} />}
              >
                Download
              </NGButton>
            </div>
            <CodeEditor
              language="yaml"
              options={{
                readOnly: true,
                style: {
                  height: "calc(100% - 50px)",
                  overflow: "auto",
                },
                className: "text-sm",
              }}
              value={jsYaml.dump(helmTemplates, { sortKeys: k8sKeySort })}
              setValue={() => {}}
              minHeight="calc(100% - 50px)"
            />
          </div>
        </Modal>
      ) : null}
    </NGFormContext.Provider>
  );
};
