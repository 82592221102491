import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { BooleanConverter } from "../../reactHooks/atoms/converters";
import { EDrawerType } from "../../components/drawer/content";

const STORAGE_KEY_LAST_ACTIVITY = "LAST_ACTIVITY";
const STORAGE_KEY_IS_NAV_COLLAPSED = "IS_NAV_COLLAPSED";
const STORAGE_KEY_IS_ORG_PINNED = "IS_ORG_PINNED";
const STORAGE_KEY_IS_GVC_PINNED = "IS_GVC_PINNED";

class UIDataMobx {
  tabId: string = uuidv4();
  initialUrl: string = window.location.pathname;

  isNavCollapsed: boolean = false;
  isOrgPinned: boolean = false;
  isGVCPinned: boolean = false;

  drawerType: EDrawerType = EDrawerType.NONE;

  constructor(initialUrl: string = "/") {
    makeAutoObservable(this);
    this.initialUrl = initialUrl;
    this.loadValue(STORAGE_KEY_IS_NAV_COLLAPSED, "isNavCollapsed", BooleanConverter);
    this.loadValue(STORAGE_KEY_IS_ORG_PINNED, "isOrgPinned", BooleanConverter);
    this.loadValue(STORAGE_KEY_IS_GVC_PINNED, "isGVCPinned", BooleanConverter);
  }

  setInitialUrl(url: string) {
    this.initialUrl = url;
  }

  clearInitialUrl() {
    this.initialUrl = "/";
  }

  saveRef(key: string, ref: string) {
    localStorage.setItem(key, String((this as any)[ref]));
  }

  setIsNavCollapsed(value: boolean) {
    this.isNavCollapsed = value;
    this.saveValue(STORAGE_KEY_IS_NAV_COLLAPSED, value);
  }

  setIsOrgPinned(value: boolean) {
    this.isOrgPinned = value;
    this.saveValue(STORAGE_KEY_IS_ORG_PINNED, value);
  }

  setIsGVCPinned(value: boolean) {
    this.isGVCPinned = value;
    this.saveValue(STORAGE_KEY_IS_GVC_PINNED, value);
  }

  setDrawerType(value: EDrawerType) {
    this.drawerType = value;
  }

  saveValue(key: string, value: any) {
    localStorage.setItem(key, String(value));
  }

  loadValue(key: string, ref: string, converter: (value: string) => void = (value) => value) {
    const savedValue = localStorage.getItem(key) || "";
    (this as any)[ref] = converter(savedValue);
  }

  updateLastActivityTimestamp() {
    this.saveValue(STORAGE_KEY_LAST_ACTIVITY, new Date().getTime());
  }

  get lastActivityTimestamp() {
    const valueString = localStorage.getItem(STORAGE_KEY_LAST_ACTIVITY) || "0";
    let value = Number(valueString);
    if (Number.isNaN(value)) {
      value = 0;
    }
    return value;
  }
}

export const UIData = new UIDataMobx(window.location.href.split(window.location.host)[1]);
