import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbsContext, useInitBreadcrumbs } from "./reactContexts/breadcrumbContext";
import { TaskChecker } from "./mobxStores/task/taskChecker";
import { Modal, notification } from "antd";
import { UIData } from "./mobxStores/uiData/uiData";
import RouteLeavingGuard from "./components/generic/routeLeavingGuard";
import { ErrorBoundary } from "./errorBoundary";
import { ConsoleContext } from "./mobxStores/consoleContext/consoleContext";
import { observer } from "mobx-react-lite";
import { getAnalytics, logEvent } from "firebase/analytics";
import { User } from "./mobxStores/user/user";
import { hotjarPageChange } from "./tools/hotjar";
import { DomainContext } from "./mobxStores/kinds/domainContext";
import { ALL_DOMAINS_UPDATE_INTERVAL_MS } from "./envVariables";
import { request } from "./services/cpln";
import { Loader } from "./components/layout/loader";
import { OptimalViewingModal } from "./optimalViewingModal";
import * as Sentry from "@sentry/react";
import { Drawer } from "./components/drawer/content";

const GlobalElementRaw: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const buttonHeightRef = React.useRef({
    current: 0,
    initial: 0,
    target: 0,
  });
  const [buttonTriggerer, setButtonTriggerer] = React.useState(0);

  // Modifying the position (height) of Sentry's button
  React.useEffect(() => {
    const callback = (entries) => {
      const actionThreshold = 0.6;
      const entry = entries[0];
      const intersectionRatio = entry.intersectionRatio;
      const target = entry.target;
      const isVisible = intersectionRatio > actionThreshold;
      const root = document.getElementById("sentry-feedback");

      if (!root) {
        return;
      }

      // Ensure that the target has loaded and has a defined height
      if (target && target.getClientRects().length > 0) {
        const targetHeight = target.getClientRects()[0].height;
        buttonHeightRef.current = {
          target: isVisible ? targetHeight : 0,
          initial: buttonHeightRef.current.current,
          current: buttonHeightRef.current.current,
        };
        setButtonTriggerer((x) => x + 1);
      } else {
        console.warn("Target height could not be determined");
      }
    };

    const observerOptions = {
      root: document.body,
      threshold: [0.0, 0.6, 1.0],
    };

    const observer = new IntersectionObserver(callback, observerOptions);
    const target = document.querySelector("footer.footer");

    if (!target) {
      return;
    }

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [pathname]);

  React.useEffect(() => {
    const valsCache = buttonHeightRef.current;

    if (Math.abs(valsCache.current - valsCache.target) < 1) {
      return;
    }

    const duration = 100;
    const interval = 5;
    const id = setInterval(() => {
      const vals = buttonHeightRef.current;
      const root = document.getElementById("sentry-feedback");

      if (!root) {
        return;
      }

      const value = `auto 20px ${vals.current}px auto`;
      (root as HTMLElement).style.setProperty("--actor-inset", value);

      const increment = Math.abs(vals.target - vals.initial) / (duration / interval);

      let newCurrent = vals.current;
      if (vals.target > vals.current) {
        newCurrent = vals.current + increment;
        if (newCurrent > vals.target) {
          newCurrent = vals.target;
          setButtonTriggerer((x) => x + 1);
        }
      } else if (vals.target < vals.current) {
        newCurrent = vals.current - increment;
        if (newCurrent < vals.target) {
          newCurrent = vals.target;
          setButtonTriggerer((x) => x + 1);
        }
      }

      buttonHeightRef.current = {
        ...vals,
        current: newCurrent,
      };
    }, interval);
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [buttonTriggerer]);

  React.useEffect(() => {
    UIData.updateLastActivityTimestamp();
    if (User.isAdmin) {
      return;
    }
    hotjarPageChange(pathname);
  }, [pathname]);

  React.useEffect(() => {
    if (!User.firebaseInitialized) {
      return;
    }
    if (User.isAdmin) {
      return;
    }
    logEvent(getAnalytics(User.getFirebaseApp()), "page_view");
  }, [pathname, User.firebaseInitialized]);

  React.useEffect(() => {
    if (ConsoleContext.redirect) {
      setTimeout(() => {
        navigate("/redirect");
      }, 0);
    }
  }, [ConsoleContext.redirect]);

  React.useEffect(() => {
    if (!ConsoleContext.org) {
      ConsoleContext.setLinkedAccount(null);
      return;
    }
    fetchLinkedAccount();
  }, [ConsoleContext.org]);

  async function fetchLinkedAccount() {
    try {
      const { data } = await request({ service: "billing-ng", url: `/org/${ConsoleContext.org}/account` });
      ConsoleContext.setLinkedAccount(data.id);
      if (ConsoleContext.org) {
        Sentry.setTag("org", ConsoleContext.org);
      }
      if (data.id) {
        Sentry.setTag("account", data.id);
      }
    } catch (e) {
      ConsoleContext.setLinkedAccount(null);
    }
  }

  React.useEffect(() => {
    if (!TaskChecker.newInviteCounter) {
      return;
    }

    notification.info({
      message: "New Task",
      description: (
        <div>
          <span style={{ color: "var(--color-link)", cursor: "pointer" }} onClick={() => navigate("/task")}>
            Click
          </span>{" "}
          to see tasks
        </div>
      ),
    });
  }, [TaskChecker.newInviteCounter]);

  React.useEffect(() => {
    const id = setTimeout(() => {
      TaskChecker.checkTasks();
    }, TaskChecker.intervalMs);
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [TaskChecker.requestCounter]);

  // Fetch all domain items for current domain endpoints, at the initialization of the app
  React.useEffect(() => {
    if (!ConsoleContext.hasOrg) {
      return;
    }
    DomainContext.requestItems();
  }, [ConsoleContext.hasOrg]);

  // Fetch all domain items for current domain endpoints, reschedule it after each request is completed
  React.useEffect(() => {
    if (!ConsoleContext.hasOrg) {
      return;
    }
    const id = setTimeout(() => {
      DomainContext.requestItems();
    }, ALL_DOMAINS_UPDATE_INTERVAL_MS);
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [ConsoleContext.hasOrg, DomainContext.requestCounter]);

  const breadcrumbsHook = useInitBreadcrumbs();

  return (
    <BreadcrumbsContext.Provider value={breadcrumbsHook}>
      <ErrorBoundary>
        <RouteLeavingGuard />
        <Outlet />
        <Drawer />
        <OptimalViewingModal />
        {ConsoleContext.isChangingContext ? (
          <div
            style={{
              position: "absolute",
              inset: 0,
              zIndex: 10_000,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
            }}
          >
            <Loader reason={"Changing context"} />
            <div
              style={{ top: "15%", color: "white" }}
              className="absolute inset-0 flex flex-col gap-2 items-center justify-center"
            >
              <span>Processing</span>
              <span>Please wait...</span>
            </div>
          </div>
        ) : null}
      </ErrorBoundary>
    </BreadcrumbsContext.Provider>
  );
};

export const GlobalElement = observer(GlobalElementRaw);
