import * as React from "react";
import { Deployment } from "../../schema/types/workload/deployment";
import { linksOf, request, resourceLink } from "../../services/cpln";
import { ngParseLink } from "../../utils/linkParser/linkParser";

interface WorkloadDeploymentsMap {
  [_: string]: Deployment[];
}

interface DeploymentsReducerState {
  link: string;
  requestedLinks: string[];
  deploymentsMap: WorkloadDeploymentsMap;
  lastModified: string;
}

interface DeploymentsReducerType {
  state: DeploymentsReducerState;
  dispatch: React.Dispatch<DeploymentsReducerAction>;
  fetchDeployments: (workloadLinks: string[], intervalMs?: number) => () => void;
}

export function useDeploymentsReducer(): DeploymentsReducerType {
  const initialState: DeploymentsReducerState = {
    link: "",
    requestedLinks: [],
    deploymentsMap: {},
    lastModified: new Date().toISOString(),
  };
  const [state, dispatch] = React.useReducer(deploymentsReducer, initialState);

  async function _fetchDeployments(links: string[]) {
    const linksByGvc: { [_: string]: string[] } = {};
    for (const link of links) {
      const { gvc } = ngParseLink(link, { useInputCtx: true });
      if (!linksByGvc[gvc]) {
        linksByGvc[gvc] = [];
      }
      linksByGvc[gvc].push(link);
    }

    try {
      if (links.length > 1) {
        const gvcs = Object.keys(linksByGvc);

        const batchPromises: Promise<any>[] = [];
        for (const gvc of gvcs) {
          const url = resourceLink("gvc", gvc) + "/workload/-deployment";
          batchPromises.push(request({ url: url }));
        }

        const batchResponses = await Promise.allSettled(batchPromises);

        for (const link of links) {
          dispatch({
            type: DeploymentsReducerActionType.REGISTER_REQUESTED_WORKLOAD,
            payload: { link: link, deployments: [] },
          });
        }

        for (const gvcIndex in gvcs) {
          const batchResponse = batchResponses[gvcIndex];
          if (batchResponse.status === "rejected") {
            continue;
          }

          for (const deployment of batchResponse.value.data.items) {
            const workloadLink = linksOf(deployment).workload;
            if (!workloadLink) {
              continue;
            }
            dispatch({
              type: DeploymentsReducerActionType.BATCH_DEPLOYMENT,
              payload: { link: workloadLink, deployments: batchResponse.value.data.items },
            });
          }
        }
      } else {
        const link = links[0];
        const deploymentLink = link + "/deployment";
        const { data } = await request({ url: deploymentLink });
        const deployments = data.items;

        dispatch({
          type: DeploymentsReducerActionType.SET_DEPLOYMENTS,
          payload: { link: link, deployments: deployments },
        });
      }
    } catch (e) {}
  }

  function fetchDeployments(links: string[], intervalMs = 0) {
    _fetchDeployments(links);
    if (intervalMs < 1) {
      return () => {};
    }
    const id = setInterval(() => {
      _fetchDeployments(links);
    }, intervalMs);
    return () => {
      clearInterval(id);
    };
  }

  // TODO can move starting a websocket and returning its unsubscribe

  return { state, dispatch, fetchDeployments };
}

export enum DeploymentsReducerActionType {
  SET_DEPLOYMENTS = "SET_DEPLOYMENTS",
  UPDATE_DEPLOYMENT = "UPDATE_DEPLOYMENT",
  CLEAR_DEPLOYMENTS = "CLEAR_DEPLOYMENTS",
  BATCH_DEPLOYMENT = "BATCH_DEPLOYMENT",
  REGISTER_REQUESTED_WORKLOAD = "REGISTER_REQUESTED_WORKLOADT",
}

interface DeploymentsReducerAction {
  type: DeploymentsReducerActionType;
  payload: { link: string; deployments: Deployment[] };
}

function deploymentsReducer(state: DeploymentsReducerState, action: DeploymentsReducerAction): DeploymentsReducerState {
  const { type, payload } = action;

  const now = new Date().toISOString();

  const requestedLinks = [...state.requestedLinks];
  if (!requestedLinks.includes(payload.link) && !!payload.link) {
    requestedLinks.push(payload.link);
  }

  switch (type) {
    case DeploymentsReducerActionType.CLEAR_DEPLOYMENTS:
      return {
        ...state,
        requestedLinks: [],
        deploymentsMap: {},
        lastModified: now,
      };
    case DeploymentsReducerActionType.SET_DEPLOYMENTS:
      return {
        ...state,
        requestedLinks: requestedLinks,
        deploymentsMap: {
          ...state.deploymentsMap,
          [payload.link]: payload.deployments,
        },
        lastModified: now,
      };
    case DeploymentsReducerActionType.UPDATE_DEPLOYMENT:
      const workloadDeployments: Deployment[] = state.deploymentsMap[payload.link] || [];
      const deployment = payload.deployments[0];

      let oldDeployment: Partial<Deployment> = {};
      const indexOf = workloadDeployments.findIndex((d) => d.name === deployment.name);
      if (indexOf >= 0) {
        oldDeployment = JSON.parse(JSON.stringify(workloadDeployments[indexOf]));
        workloadDeployments.splice(indexOf, 1);
      }
      workloadDeployments.push(Object.assign(oldDeployment, deployment, { kind: "deployment" }));

      return {
        ...state,
        requestedLinks: requestedLinks,
        deploymentsMap: {
          ...state.deploymentsMap,
          [payload.link]: workloadDeployments,
        },
        lastModified: now,
      };
    case DeploymentsReducerActionType.BATCH_DEPLOYMENT:
      const deploymentsMap: { [_: string]: Deployment[] } = JSON.parse(JSON.stringify(state.deploymentsMap));

      for (const deployment of payload.deployments) {
        const deploymentWorkloadLink = linksOf(deployment).workload!;
        deploymentsMap[deploymentWorkloadLink] = [];
      }

      for (const deployment of payload.deployments) {
        const deploymentWorkloadLink = linksOf(deployment).workload!;
        deploymentsMap[deploymentWorkloadLink].push(deployment);
      }

      return {
        ...state,
        requestedLinks: requestedLinks,
        deploymentsMap: deploymentsMap,
        lastModified: now,
      };
    case DeploymentsReducerActionType.REGISTER_REQUESTED_WORKLOAD:
      return {
        ...state,
        requestedLinks: requestedLinks,
      };
    default:
      return state;
  }
}
