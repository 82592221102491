import { Instance, types } from "mobx-state-tree";
import { Mk8sDraftModel } from "./mk8s.draft";
import { Mk8sDraftFirewallModel } from "./mk8s.draft.firewall";
import { defaultValues } from "../base";
import { mk8sMobx } from "../kinds/mk8s/mk8s";
import { Mk8sDraftNetworkingModel } from "./mk8s.draft.providerNetworking";
import { Mk8sDraftProviderGenericModel } from "./mk8s.draft.provider.generic";
import { Mk8sDraftProviderHetznerModel } from "./mk8s.draft.provider.hetzner";
import { Mk8sDraftAutoscalerModel } from "./mk8s.draft.providerAutoscaler";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { Mk8sDraftProviderAWSModel } from "./mk8s.draft.provider.aws";
import { Mk8sDraftProviderEphemeralModel } from "./mk8s.draft.provider.ephemeral";
import { Mk8sDraftProviderLinodeModel } from "./mk8s.draft.provider.linode";
import { Mk8sDraftProviderOblivusModel } from "./mk8s.draft.provider.oblivus";
import { Mk8sDraftProviderLambdalabsModel } from "./mk8s.draft.provider.lambdalabs";
import { Mk8sDraftProviderPaperspaceModel } from "./mk8s.draft.provider.paperspace";
import { Mk8sDraftProviderTritonModel } from "./mk8s.draft.provider.triton";
import { Mk8sDraftProviderTritonConnectionModel } from "./mk8s.draft.provider.triton.connection";
import { Mk8sDraftAddonFlexibleModel } from "./mk8s.draft.addon.flexible";
import { Mk8sDraftAddonAzureWorkloadIdentityModel } from "./mk8s.draft.addon.azureWorkloadIdentity";
import { Mk8sDraftAddonMetricsModel } from "./mk8s.draft.addon.metrics";
import { Mk8sDraftAddonLogsModel } from "./mk8s.draft.addon.logs";
import { Mk8sDraftAddonNvidiaModel } from "./mk8s.draft.addon.nvidia";
import { Mk8sDraftAddonAWSEFSModel } from "./mk8s.draft.addon.awsefs";
import { Mk8sDraftAddonAWSECRModel } from "./mk8s.draft.addon.awsecr";
import { Mk8sDraftAddonAWSELBModel } from "./mk8s.draft.addon.awselb";
import { Mk8sDraftAddonAzureACRModel } from "./mk8s.draft.addon.azureACR";
import { TagsNewModel } from "../../mobxDataModels/tagsNewModel";
import { kindMobxToTagsModel } from "../mobxUtilts";
import { Mk8sDraftProviderDigitalOceanModel } from "./mk8s.draft.provider.digitalocean";
import { Mk8sDraftProviderTritonLoadBalancerModel } from "./mk8s.draft.provider.triton.loadBalancer";
import { Mk8sDraftProviderTritonLoadBalancerManualModel } from "./mk8s.draft.provider.triton.loadBalancer.manual";

/*
org link
*/
export const Mk8sDraftStoreModel = types
  .model({
    orgName: types.string,
    orgLink: types.string,
    edit: types.maybe(Mk8sDraftModel),
  })
  .actions((self) => ({
    new() {
      self.edit = Mk8sDraftModel.create({
        _new: true,
        firewall: Mk8sDraftFirewallModel.create({
          _items: defaultValues.mk8s.firewall,
        }),
      });
    },
    start(mk8s: mk8sMobx) {
      self.edit = Mk8sDraftModel.create({
        _itemVersion: mk8s.version,
        _name: mk8s.name,
        _description: mk8s.description,
        _version: mk8s.spec.version,
        _provider: !!mk8s.spec.provider.aws
          ? "aws"
          : !!mk8s.spec.provider.digitalocean
          ? "digitalocean"
          : !!mk8s.spec.provider.ephemeral
          ? "ephemeral"
          : !!mk8s.spec.provider.generic
          ? "generic"
          : !!mk8s.spec.provider.hetzner
          ? "hetzner"
          : !!mk8s.spec.provider.lambdalabs
          ? "lambdalabs"
          : !!mk8s.spec.provider.linode
          ? "linode"
          : !!mk8s.spec.provider.oblivus
          ? "oblivus"
          : !!mk8s.spec.provider.paperspace
          ? "paperspace"
          : !!mk8s.spec.provider.triton
          ? "triton"
          : "generic",
        provider_aws: !!mk8s.spec.provider.aws
          ? Mk8sDraftProviderAWSModel.create({
              _region: mk8s.spec.provider.aws.region,
              _image: {
                recommended: mk8s.spec.provider.aws.image.recommended || "",
                exact: mk8s.spec.provider.aws.image.exact || "",
              },
              _deployRoleArn: mk8s.spec.provider.aws.deployRoleArn,
              _vpcId: mk8s.spec.provider.aws.vpcId,
              _keyPair: mk8s.spec.provider.aws.keyPair,
              _diskEncryptionKeyArn: mk8s.spec.provider.aws.diskEncryptionKeyArn,
              securityGroupIds: ListOfItemsModel.create({
                _items: (mk8s.spec.provider.aws.securityGroupIds || []).map((v) => ({ firstValue: v })),
              }),
              deployRoleChain: ListOfItemsModel.create({
                _items: (mk8s.spec.provider.aws.deployRoleChain || []).map((v) => ({
                  firstValue: v.roleArn,
                  secondValue: v.externalId,
                  thirdValue: v.sessionNamePrefix,
                })),
              }),
              extraNodePolicies: ListOfItemsModel.create({
                _items: (mk8s.spec.provider.aws.extraNodePolicies || []).map((v) => ({
                  firstValue: v,
                })),
              }),
              _awsTags: mk8s.spec.provider.aws.awsTags,
              _skipCreateRoles: mk8s.spec.provider.aws.skipCreateRoles,
              _nodePools: mk8s.spec.provider.aws.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  instanceTypes: [...n.instanceTypes],
                  overrideImage: n.overrideImage.recommended
                    ? { recommended: n.overrideImage.recommended }
                    : { exact: n.overrideImage.exact },
                  bootDiskSize: Number(n.bootDiskSize),
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                  onDemandBaseCapacity: n.onDemandBaseCapacity,
                  onDemandPercentageAboveBaseCapacity: n.onDemandPercentageAboveBaseCapacity,
                  spotAllocationStrategy: n.spotAllocationStrategy,
                  subnetIds: [...n.subnetIds],
                  extraSecurityGroupIds: [...n.extraSecurityGroupIds],
                };
              }),
              autoscaler: mk8s.spec.provider.aws.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.aws.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.aws.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.aws.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.aws.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
              _preInstallScript: mk8s.spec.provider.aws.preInstallScript,
              networking: mk8s.spec.provider.aws.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.aws.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.aws.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderAWSModel.create(),
        provider_digitalocean: !!mk8s.spec.provider.digitalocean
          ? Mk8sDraftProviderDigitalOceanModel.create({
              _region: mk8s.spec.provider.digitalocean.region,
              _tokenSecretLink: mk8s.spec.provider.digitalocean.tokenSecretLink,
              _vpcId: mk8s.spec.provider.digitalocean.vpcId,
              _image: mk8s.spec.provider.digitalocean.image,
              _sshKeys: [...mk8s.spec.provider.digitalocean.sshKeys],
              _extraSshKeys: [...mk8s.spec.provider.digitalocean.extraSshKeys],
              _reservedIPs: [...mk8s.spec.provider.digitalocean.reservedIPs],
              _digitalOceanTags: [...mk8s.spec.provider.digitalocean.digitalOceanTags],
              _nodePools: mk8s.spec.provider.digitalocean.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  dropletSize: n.dropletSize,
                  overrideImage: n.overrideImage,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              autoscaler: mk8s.spec.provider.digitalocean.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.digitalocean.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.digitalocean.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.digitalocean.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.digitalocean.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
              _preInstallScript: mk8s.spec.provider.digitalocean.preInstallScript,
              networking: mk8s.spec.provider.digitalocean.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.digitalocean.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.digitalocean.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderDigitalOceanModel.create(),
        provider_ephemeral: !!mk8s.spec.provider.ephemeral
          ? Mk8sDraftProviderEphemeralModel.create({
              _location: mk8s.spec.provider.ephemeral.location,
              _nodePools: mk8s.spec.provider.ephemeral.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  count: n.count,
                  arch: n.arch,
                  flavor: n.flavor,
                  cpu: n.cpu,
                  memory: n.memory,
                };
              }),
            })
          : Mk8sDraftProviderEphemeralModel.create(),
        provider_generic: !!mk8s.spec.provider.generic
          ? Mk8sDraftProviderGenericModel.create({
              _location: mk8s.spec.provider.generic.location,
              _nodePools: mk8s.spec.provider.generic.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              networking: mk8s.spec.provider.generic.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.generic.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.generic.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderGenericModel.create(),
        provider_hetzner: !!mk8s.spec.provider.hetzner
          ? Mk8sDraftProviderHetznerModel.create({
              _region: mk8s.spec.provider.hetzner.region,
              _tokenSecretLink: mk8s.spec.provider.hetzner.tokenSecretLink,
              _networkId: mk8s.spec.provider.hetzner.networkId,
              _firewallId: mk8s.spec.provider.hetzner.firewallId,
              _image: mk8s.spec.provider.hetzner.image,
              _sshKey: mk8s.spec.provider.hetzner.sshKey,
              _hetznerLabels: mk8s.spec.provider.hetzner.hetznerLabels,
              _floatingIPSelector: mk8s.spec.provider.hetzner.floatingIPSelector,
              _nodePools: mk8s.spec.provider.hetzner.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  overrideImage: n.overrideImage,
                  serverType: n.serverType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _dedicatedServerNodePools: mk8s.spec.provider.hetzner.dedicatedServerNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              autoscaler: mk8s.spec.provider.hetzner.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.hetzner.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.hetzner.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.hetzner.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.hetzner.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
              _preInstallScript: mk8s.spec.provider.hetzner.preInstallScript,
              networking: mk8s.spec.provider.hetzner.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.hetzner.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.hetzner.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
            })
          : Mk8sDraftProviderHetznerModel.create(),
        provider_lambdalabs: !!mk8s.spec.provider.lambdalabs
          ? Mk8sDraftProviderLambdalabsModel.create({
              _region: mk8s.spec.provider.lambdalabs.region,
              _unmanagedNodePools: mk8s.spec.provider.lambdalabs.unmanagedNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              _nodePools: mk8s.spec.provider.lambdalabs.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  instanceType: n.instanceType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _preInstallScript: mk8s.spec.provider.lambdalabs.preInstallScript,
              _tokenSecretLink: mk8s.spec.provider.lambdalabs.tokenSecretLink,
              _sshKey: mk8s.spec.provider.lambdalabs.sshKey,
              _fileSystems: [...mk8s.spec.provider.lambdalabs.fileSystems],
              autoscaler: mk8s.spec.provider.lambdalabs.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.lambdalabs.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.lambdalabs.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.lambdalabs.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.lambdalabs.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderLambdalabsModel.create(),
        provider_linode: !!mk8s.spec.provider.linode
          ? Mk8sDraftProviderLinodeModel.create({
              _region: mk8s.spec.provider.linode.region,
              _image: mk8s.spec.provider.linode.image,
              _nodePools: mk8s.spec.provider.linode.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  serverType: n.serverType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                  overrideImage: n.overrideImage,
                  subnetId: n.subnetId,
                };
              }),
              _preInstallScript: mk8s.spec.provider.linode.preInstallScript,
              _vpcId: mk8s.spec.provider.linode.vpcId,
              _firewallId: mk8s.spec.provider.linode.firewallId,
              _tokenSecretLink: mk8s.spec.provider.linode.tokenSecretLink,
              _authorizedUsers: [...mk8s.spec.provider.linode.authorizedUsers],
              _authorizedKeys: [...mk8s.spec.provider.linode.authorizedKeys],
              networking: mk8s.spec.provider.linode.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.linode.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.linode.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
              autoscaler: mk8s.spec.provider.linode.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.linode.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.linode.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.linode.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.linode.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderLinodeModel.create(),
        provider_oblivus: !!mk8s.spec.provider.oblivus
          ? Mk8sDraftProviderOblivusModel.create({
              _datacenter: mk8s.spec.provider.oblivus.datacenter,
              _unmanagedNodePools: mk8s.spec.provider.oblivus.unmanagedNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              _nodePools: mk8s.spec.provider.oblivus.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  flavor: n.flavor,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _preInstallScript: mk8s.spec.provider.oblivus.preInstallScript,
              _tokenSecretLink: mk8s.spec.provider.oblivus.tokenSecretLink,
              _sshKeys: [...mk8s.spec.provider.oblivus.sshKeys],
              autoscaler: mk8s.spec.provider.oblivus.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.oblivus.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.oblivus.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.oblivus.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.oblivus.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderOblivusModel.create(),
        provider_paperspace: !!mk8s.spec.provider.paperspace
          ? Mk8sDraftProviderPaperspaceModel.create({
              _region: mk8s.spec.provider.paperspace.region,
              _unmanagedNodePools: mk8s.spec.provider.paperspace.unmanagedNodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                };
              }),
              _nodePools: mk8s.spec.provider.paperspace.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  bootDiskSize: n.bootDiskSize,
                  publicIpType: n.publicIpType,
                  machineType: n.machineType,
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              _preInstallScript: mk8s.spec.provider.paperspace.preInstallScript,
              _networkId: mk8s.spec.provider.paperspace.networkId,
              _tokenSecretLink: mk8s.spec.provider.paperspace.tokenSecretLink,
              _sharedDrives: [...mk8s.spec.provider.paperspace.sharedDrives],
              _userIds: [...mk8s.spec.provider.paperspace.userIds],
              autoscaler: mk8s.spec.provider.paperspace.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.paperspace.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.paperspace.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.paperspace.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.paperspace.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
            })
          : Mk8sDraftProviderPaperspaceModel.create(),
        provider_triton: !!mk8s.spec.provider.triton
          ? Mk8sDraftProviderTritonModel.create({
              connection: Mk8sDraftProviderTritonConnectionModel.create({
                _url: mk8s.spec.provider.triton.connection.url,
                _account: mk8s.spec.provider.triton.connection.account,
                _user: mk8s.spec.provider.triton.connection.user,
                _tokenSecretLink: mk8s.spec.provider.triton.connection.privateKeySecretLink,
              }),
              _location: mk8s.spec.provider.triton.location,
              _privateNetworkId: mk8s.spec.provider.triton.privateNetworkId,
              _firewallEnabled: mk8s.spec.provider.triton.firewallEnabled,
              _imageId: mk8s.spec.provider.triton.imageId,
              _sshKeys: [...mk8s.spec.provider.triton.sshKeys],
              _nodePools: mk8s.spec.provider.triton.nodePools.map((n) => {
                return {
                  name: n.name,
                  labels: Object.entries(n.labels || {}).map(([key, value]: any) => ({ key: key, value: value })),
                  taints: n.taints.map((t) => ({ key: t.key, value: t.value, effect: t.effect })),
                  packageId: n.packageId,
                  overrideImageId: n.overrideImageId,
                  publicNetworkId: n.publicNetworkId,
                  privateNetworkIds: [...n.privateNetworkIds],
                  tritonTags: Object.entries(n.tritonTags || {}).map(([key, value]: any) => ({
                    key: key,
                    value: value,
                  })),
                  minSize: n.minSize,
                  maxSize: n.maxSize,
                };
              }),
              autoscaler: mk8s.spec.provider.triton.autoscaler
                ? Mk8sDraftAutoscalerModel.create({
                    expanders: ListOfItemsModel.create({
                      _items: mk8s.spec.provider.triton.autoscaler.expander.map((i) => ({ firstValue: i })),
                    }),
                    _unneededTime: mk8s.spec.provider.triton.autoscaler.unneededTime,
                    _unreadyTime: mk8s.spec.provider.triton.autoscaler.unreadyTime,
                    _utilizationThreshold: mk8s.spec.provider.triton.autoscaler.utilizationThreshold,
                  })
                : Mk8sDraftAutoscalerModel.create(),
              _preInstallScript: mk8s.spec.provider.triton.preInstallScript,
              networking: mk8s.spec.provider.triton.networking
                ? Mk8sDraftNetworkingModel.create({
                    _serviceNetwork: mk8s.spec.provider.triton.networking.serviceNetwork,
                    _podNetwork: mk8s.spec.provider.triton.networking.podNetwork,
                  })
                : Mk8sDraftNetworkingModel.create(),
              loadBalancer: mk8s.spec.provider.triton.loadBalancer
                ? Mk8sDraftProviderTritonLoadBalancerModel.create({
                    _method: mk8s.spec.provider.triton.loadBalancer.gateway ? "gateway" : "manual",
                    manual: mk8s.spec.provider.triton.loadBalancer.manual
                      ? Mk8sDraftProviderTritonLoadBalancerManualModel.create({
                          _packageId: mk8s.spec.provider.triton.loadBalancer.manual.packageId,
                          _imageId: mk8s.spec.provider.triton.loadBalancer.manual.imageId,
                          _publicNetworkId: mk8s.spec.provider.triton.loadBalancer.manual.publicNetworkId,
                          _privateNetworkIds: [
                            ...(mk8s.spec.provider.triton.loadBalancer.manual.privateNetworkIds || []),
                          ],
                          _metadata: mk8s.spec.provider.triton.loadBalancer.manual.metadata,
                          _tags: mk8s.spec.provider.triton.loadBalancer.manual.tags,
                          _count: mk8s.spec.provider.triton.loadBalancer.manual.count,
                          _cnsInternalDomain: mk8s.spec.provider.triton.loadBalancer.manual.cnsInternalDomain,
                          _cnsPublicDomain: mk8s.spec.provider.triton.loadBalancer.manual.cnsPublicDomain,
                        })
                      : Mk8sDraftProviderTritonLoadBalancerManualModel.create(),
                  })
                : Mk8sDraftProviderTritonLoadBalancerModel.create(),
            })
          : Mk8sDraftProviderTritonModel.create(),
        addon_awsEcr: !!mk8s.spec.addOns?.awsECR
          ? Mk8sDraftAddonAWSECRModel.create({
              _isEnabled: true,
              _roleArn: mk8s.spec.addOns.awsECR.roleArn,
            })
          : Mk8sDraftAddonAWSECRModel.create(),
        addon_awsEfs:
          !!mk8s.spec.provider.aws && !!mk8s.spec.addOns?.awsEFS
            ? Mk8sDraftAddonAWSEFSModel.create({
                _isEnabled: true,
                _roleArn: mk8s.spec.addOns.awsEFS.roleArn,
              })
            : Mk8sDraftAddonAWSEFSModel.create(),
        addon_awsElb: !!mk8s.spec.addOns?.awsELB
          ? Mk8sDraftAddonAWSELBModel.create({
              _isEnabled: true,
              _roleArn: mk8s.spec.addOns.awsELB.roleArn,
            })
          : Mk8sDraftAddonAWSELBModel.create(),
        addon_awsWorkloadIdentity: !!mk8s.spec.addOns?.awsWorkloadIdentity
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_azureAcr: !!mk8s.spec.addOns?.azureACR
          ? Mk8sDraftAddonAzureACRModel.create({
              _isEnabled: true,
              _clientId: mk8s.spec.addOns.azureACR.clientId,
            })
          : Mk8sDraftAddonAzureACRModel.create(),
        addon_azureWorkloadIdentity: !!mk8s.spec.addOns?.azureWorkloadIdentity
          ? Mk8sDraftAddonAzureWorkloadIdentityModel.create({
              _isEnabled: true,
              _tenantId: mk8s.spec.addOns?.azureWorkloadIdentity?.tenantId,
            })
          : Mk8sDraftAddonAzureWorkloadIdentityModel.create(),
        addon_dashboard: !!mk8s.spec.addOns?.dashboard
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_localPathStorage: !!mk8s.spec.addOns?.localPathStorage
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        addon_logs: !!mk8s.spec.addOns?.logs
          ? Mk8sDraftAddonLogsModel.create({
              _isEnabled: true,
              _auditEnabled: mk8s.spec.addOns?.logs?.auditEnabled,
              _includeNamespaces: mk8s.spec.addOns?.logs?.includeNamespaces,
              _excludeNamespaces: mk8s.spec.addOns?.logs?.excludeNamespaces,
            })
          : Mk8sDraftAddonLogsModel.create(),
        addon_metrics: !!mk8s.spec.addOns?.metrics
          ? Mk8sDraftAddonMetricsModel.create({
              _isEnabled: true,
              _coreDns: mk8s.spec.addOns?.metrics?.coreDns,
              _kubelet: mk8s.spec.addOns?.metrics?.kubelet,
              _kubeState: mk8s.spec.addOns?.metrics?.kubeState,
              _nodeExporter: mk8s.spec.addOns?.metrics?.nodeExporter,
              _apiserver: mk8s.spec.addOns?.metrics?.apiserver,
              _cadvisor: mk8s.spec.addOns?.metrics?.cadvisor,
              _scrapeAnnotated: !!mk8s.spec.addOns?.metrics?.scrapeAnnotated,
              _scrapeAnnotated_intervalSeconds: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.intervalSeconds,
              _scrapeAnnotated_includeNamespaces: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.includeNamespaces,
              _scrapeAnnotated_excludeNamespaces: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.excludeNamespaces,
              _scrapeAnnotated_retainLabels: mk8s.spec.addOns?.metrics?.scrapeAnnotated?.retainLabels,
            })
          : Mk8sDraftAddonMetricsModel.create(),
        addon_nvidia: !!mk8s.spec.addOns?.nvidia
          ? Mk8sDraftAddonNvidiaModel.create({
              _isEnabled: true,
            })
          : Mk8sDraftAddonNvidiaModel.create(),
        addon_sysbox: !!mk8s.spec.addOns?.sysbox
          ? Mk8sDraftAddonFlexibleModel.create({ _isEnabled: true })
          : Mk8sDraftAddonFlexibleModel.create(),
        firewall:
          mk8s.spec.firewall.length > 0
            ? Mk8sDraftFirewallModel.create({
                _items: mk8s.spec.firewall.map((f) => {
                  return {
                    sourceCIDR: f.sourceCIDR,
                    description: f.description || "",
                  };
                }),
              })
            : Mk8sDraftFirewallModel.create(),
        tags: TagsNewModel.create({
          tags: kindMobxToTagsModel(mk8s),
        }),
      });

      if (self.edit.provider.value !== "aws") {
        self.edit.addon_awsEcr.setRoleArnRequired(true);
        self.edit.addon_awsElb.setRoleArnRequired(true);
      }
    },
    reset() {
      self.edit?.reset();
    },
  }));
export interface Mk8sDraftStoreMobx extends Instance<typeof Mk8sDraftStoreModel> {}
